import React, { Component } from 'react';
import { Button, Progress, Card, Tooltip, Input } from 'antd';
import history from '../../../history';

class Connection extends Component {
    state = {
        testKey: null,
        liveKey: null,
        authKey: null,
    }

    componentDidMount() {
        const { testKey, liveKey, authKey } = this.props.settings;
        this.setState({ testKey, liveKey, authKey });
    }

    componentDidUpdate(prevProps) {
        if (this.props.settings.testKey !== prevProps.settings.testKey) {
            this.setState({ testKey: this.props.settings.testKey });
        }
        if (this.props.settings.liveKey !== prevProps.settings.liveKey) {
            this.setState({ liveKey: this.props.settings.liveKey });
        }
        if (this.props.settings.authKey !== prevProps.settings.authKey) {
            this.setState({ authKey: this.props.settings.authKey });
        }
    }

    saveKeys = () => {
        const { testKey, liveKey, authKey } = this.state;
        console.log('saving keys', testKey, liveKey, authKey);
        this.props.updateUser({ testKey, liveKey, authKey });
    }

    render() {
        const { testKey, liveKey, authKey } = this.state;
        
        return (
            <div style={{ textAlign: 'left' }}>
                <Card title={<span>Connection</span>} style={{ width: 500 }}>
                    <div>
                        Test Key
                        <Input value={testKey} onChange={e => this.setState({ testKey: e.target.value })} />
                    </div>
                    <div>
                        Live Key
                        <Input value={liveKey} onChange={e => this.setState({ liveKey: e.target.value })} />
                    </div>
                    <div>
                        Auth Key
                        <Input value={authKey} onChange={e => this.setState({ authKey: e.target.value })} />
                    </div>
                    <Button onClick={this.saveKeys}>Save</Button>
                </Card>
            </div>
        );
    }
}

export default Connection;
