import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Table, Badge, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import _ from 'lodash';

class DashboardTable extends Component {
    getColumns = () => {
        return [
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
            },
            {
                title: 'Actions',
                dataIndex: 'Actions',
                key: 'actions',
                render: (one, two) => {
                    return (
                    <div>
                        <Button type="primary" onClick={() => this.props.onOpenSite(two._id)} style={{ marginRight: 10 }}>Open</Button>
                        <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => this.props.onRemove(two._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                               <Button type="secondary" icon={<CloseOutlined />}>Remove</Button>
                            </Popconfirm>
                    </div>
                    )
                },
            }
        ];
    }

    expandedRowRender = record => {
        return (
            <div>
                <ReactJson src={record}  />
            </div>
        )
    }

    render() {
        const { data, isLoading } = this.props;
        const tableData = _.sortBy(data, 'date');
        // console.log('about to render table with data', tableData);
       
        return (
            <Table
                dataSource={tableData}
                columns={this.getColumns()}
                loading={isLoading}
                pagination={{ pageSize: 20, hideOnSinglePage: true }}
                rowKey='_id'
                size="small"
                expandedRowRender={this.expandedRowRender}
            />
        ) 
    }
}

export default DashboardTable;
