import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
    List,
    Button,
    Switch,
    Modal,
    DatePicker,
    Input,
    Result,
    Divider,
} from 'antd';
import { SyncOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { TextArea } = Input;

const templates = {
    sent: "Замовлення вiдправлено ТТН 00000000000000 Iнтернет Магазин Лiлiя",
    paymentSum: "Сумма к оплате 0000 на Карту 5375 4141 2445 1385 Iнтернет Магазин Лiлiя",
    paymentAdvance: "Внесите аванс в сумме 0000 на Карту 5375 4141 2445 1385 Iнтернет Магазин Лiлiя",
}

class MessageModal extends Component {
    state = { 
        text: templates.sent,
        phone: null,
    }

    // cleanPhone = () => {

    // }

    componentDidMount() {
        const { order } = this.props;
        if (order) {
            this.setState({ phone: order.agent.full.phone })
        }
    }

    cleanPhone = () => {
        let phone = this.state.phone;
        phone = phone.split(' ').join('');
        phone = phone.split('.').join('');
        phone = phone.split('-').join('');
        phone = phone.split('(').join('');
        phone = phone.split(')').join('');
        this.setState({ phone });
    }

    sendSMS = () => {
        const { phone, text } = this.state;
        const { order } = this.props;
        const config = { phone, text };
        this.props.onSendSMS(config, order.id);
    }

    renderSendSMS = () => {
        const { text, phone } = this.state;
        const { SMSSendLoading, SMSSendError } = this.props;
        return <div>
            <div style={{ margin: '10px 0' }}>
                <div>Шаблон Сообщения:</div>
                <Button style={{ marginRight: '10px' }} size="small" onClick={() => this.setState({ text: templates.sent })}>ТТН</Button>
                <Button style={{ marginRight: '10px' }} size="small" onClick={() => this.setState({ text: templates.paymentSum })}>Оплата Cумма</Button>
                <Button size="small" onClick={() => this.setState({ text: templates.paymentAdvance })}>Оплата Аванс</Button>
            </div>
            <div style={{ marginBottom: 10 }}>
                <div>Номер</div>
                <div style={{ display: 'flex' }}>
                    <Input value={phone} onChange={e => this.setState({ phone: e.target.value })} />
                    <Button style={{ marginLeft: 10 }} onClick={this.cleanPhone}>Почистить</Button>
                </div>
            </div>
            <TextArea 
                value={text} 
                onChange={e => this.setState({ text: e.target.value })} 
                rows={4}
                // maxLength={133} 
                style={{ marginBottom: 10 }}
            />
            <div style={{ marginBottom: 10, fontSize: 13 }}>Количество символов: {text.length}</div>
            <div style={{ marginBottom: 10, fontSize: 13 }}>{text.length > 133 && 'Превышено количество символов для смс. Макс 133.'}</div>
            <div style={{ marginBottom: 10, fontSize: 13, color: 'red' }}>{SMSSendError && 'Ошибка отправки СМС'}</div>
            <Button onClick={this.sendSMS} loading={SMSSendLoading} type="primary" disabled={text.length > 133}>Отправить</Button>
        </div>
    }

    renderSMSSuccess = () => {
        const { onCloseSent } = this.props;
        return <div>
            <Result
                status="success"
                title="СМС отправлено"
                extra={[
                <Button type="primary" key="console" onClick={onCloseSent}>
                    Закрыть
                </Button>,
                ]}
            />
        </div>
    }

    render() {
        const { isVisible, onClose, SMSSendSuccess } = this.props;

        return (
            <div style={{ textAlign: 'left' }} className="admin-panel-tab">
                <Modal
                    title="Отправка СМС"
                    visible={isVisible}
                    footer={null}
                    onCancel={onClose}
                >
                    {!SMSSendSuccess && this.renderSendSMS()}
                    {SMSSendSuccess && this.renderSMSSuccess()}
                </Modal>
            </div>
        );
    }
}

export default MessageModal;