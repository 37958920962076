import React, { Component } from 'react';
import { Button, Modal, Select, Spin } from 'antd';
import moment from 'moment';

import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

class CancelAccountModal extends Component {
    state = { selectedTerm: 1 }
    toggleCancelPlanModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    getWebsiteCount = websites => {
        if (websites === 999) return 'Unlimited';
        return websites;
    }

    render() {
        const {
            isVisible,
            settings,
            onCloseModal,
            billing,
            onPauseAccount,
            onShowCancelAccountModal,
        } = this.props;

        const {
            pauseAccountLoading,
            pauseAccountError,
        } = billing;
        const {
            periodEnd,
        } = settings;

        return (
            <Modal
                title="Pause your subscription instead?"
                visible={isVisible}
                onCancel={onCloseModal}
                width={600}
                footer={[(
                    <Button
                        onClick={() => onPauseAccount(this.state.selectedTerm)}
                        disabled={pauseAccountLoading}
                        key="OK"
                        type="primary"
                    >
                        {pauseAccountLoading && <Spin indicator={<LoadingOutlined style={{ marginRight: '10px' }} spin />} />}
                        Pause subscription
                    </Button>),
                <Button onClick={onShowCancelAccountModal} disabled={pauseAccountLoading} key="NOP">Nope, I still want to cancel</Button>
                ]}
            >
                <div style={{ marginBottom: '10px' }}>
                    You can pause your subscription's renewal from 1 to 3 months.
            </div>
                <ul style={{ margin: '20px 10px' }}>
                    <li style={{ marginBottom: '10px' }}>Your subscription's renewal will be paused on <b>{moment.unix(periodEnd).format('DD MMMM YYYY')}</b>.</li>
                    <li style={{ marginBottom: '10px' }}>At the end of the pause, it will automatically restart and you will get a <b>20% discount</b> on the first month.</li>
                    <li style={{ marginBottom: '10px' }}>During the pause, you will be moved back to a Free plan and you won't be charged. You can cancel your paused subscription at any time.</li>
                    <li style={{ marginBottom: '10px' }}>You will continue having access to all data from your account.</li>
                    {/* <li>One week before the end of the pause, we will send you a reminder by email.</li> */}
                </ul>

                <div style={{ padding: '10px 0' }}><b>For how long would you like to pause your subscription?</b></div>
                <Select
                    style={{ width: 200, padding: '0 0 10px 0' }}
                    onChange={selectedTerm => this.setState({ selectedTerm: selectedTerm })}
                    placeholder="select site id"
                    defaultValue={this.state.selectedTerm}
                >
                    <Option key={1} value={1}>One month</Option>
                    <Option key={2} value={2}>Two months</Option>
                    <Option key={3} value={3}>Three months</Option>
                </Select>
                <div>Your subscription will renew automatically on <b>{moment.unix(periodEnd).add(this.state.selectedTerm, 'months').format('DD MMMM YYYY')}</b> with a 20% discount on the 1st month.</div>
                {pauseAccountError && <div className="ant-form-extra" style={{ marginTop: '10px', fontSize: '12px' }}>There has been an error pausing your account. Please reach out to support of the error persists</div>}
            </Modal>
        )
    }
}

export default CancelAccountModal;