import React, { Component } from 'react';
import { Button, Progress, Card, Tooltip } from 'antd';
import history from '../../../history';

import EditProfile from './EditProfile';
import EditEmail from './EditEmail';
import ChangePassword from './ChangePassword';

class Billing extends Component {
    render() {
        return (
            <div>
                <EditProfile {...this.props} />
                <EditEmail {...this.props} />
                <ChangePassword {...this.props} />
            </div>
        );
    }
}

export default Billing;
