import React, { Component } from 'react';
import { Button, Modal, Alert } from 'antd';

import BillingInformationForm, { validateBillingInformationForm } from '../../../components/CheckoutModal/BillingInformationForm';

class ChangeAddressModal extends Component {
    state = {
        validationErrors: {},
        // we set the state from inside the BillingInformationForm because we control submit ChangeAddressModal
    }

    setProp = (prop, value) => {
        this.setState({ [prop]: value });
    }

    onSubmit = () => {
        const { billing, changeBillingDetails } = this.props;
        const { validationErrors, ...values } = this.state;
        const { address, name, notes } = billing.billingDetails;

        const nextBillingDetails = { name, notes, sendInvoices: false, ...address, ...values };

        const billingDetailsValid = validateBillingInformationForm(nextBillingDetails, errs => this.setState({ validationErrors: { ...validationErrors, ...errs } }));
        if (!billingDetailsValid) return false;

        changeBillingDetails(nextBillingDetails);
    }

    render() {
        const {
            isVisible,
            onCloseModal,
            billing,
            settings,
        } = this.props;

        const { sendInvoices, email } = settings;

        const {
            changeAddressLoading,
            changeAddressError,
            changeAddressSuccess,
            billingDetails,
        } = billing;
        const { address, name, notes } = billingDetails;

        const { validationErrors } = this.state;

        return (
            <Modal
                title="Update Billing Information"
                visible={isVisible}
                onCancel={onCloseModal}
                width={600}
                bodyStyle={{ padding: '0 24px' }}
                footer={[(
                    <Button
                        type="primary"
                        onClick={this.onSubmit}
                        disabled={changeAddressLoading || Object.keys(this.state).length === 0}
                        key="OK"
                    >
                        Save
                    </Button>),
                (<Button
                    onClick={onCloseModal}
                    disabled={changeAddressLoading}
                    key="cancel"
                >
                    Close
                </Button>),
                ]}
            >
                {changeAddressSuccess && <Alert message="Billing information has been changed." type="success" style={{ marginBottom: '10px' }} />}
                {changeAddressError && <Alert message="There has been an error changing your details. Please reach out to support of the error persists" type="error" style={{ marginBottom: '10px' }} />}
                <BillingInformationForm billingDetails={{ name, notes, ...address }} sendInvoices={sendInvoices} email={email} setProp={this.setProp} errors={validationErrors} />
            </Modal>
        )
    }
}

export default ChangeAddressModal;