import React, { Component } from 'react';
import { Button, Progress, Card, Tooltip } from 'antd';
import history from '../../../history';

class Subscription extends Component {
    render() {
        return (
            <div style={{ textAlign: 'left' }}>
                <Card title={<span>Subscription</span>} style={{ width: 500 }}>
                    Subscription
                </Card>
            </div>
        );
    }
}

export default Subscription;
