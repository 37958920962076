import { handleActions } from 'redux-actions';
import { message } from 'antd';
import history from '../history';
import fetch from '../services/fetch';
import { getStripeKey } from './environment';
import tracker from '../services/eventTracking';
import logger from '../services/errorlogger';

import { actions as SettingsActions } from './SettingsReducer';

export const actionTypes = {
    CHARGE_RESET: 'CHARGE_RESET',
    CHARGE_LOADING: 'CHARGE_LOADING',
    CHARGE_SUCCESS: 'CHARGE_SUCCESS',
    CHARGE_ERROR: 'CHARGE_ERROR',
    CONFIRM_CHARGE_LOADING: 'CONFIRM_CHARGE_LOADING',
    CONFIRM_CHARGE_SUCCESS: 'CONFIRM_CHARGE_SUCCESS',
    CONFIRM_CHARGE_ERROR: 'CONFIRM_CHARGE_ERROR',
    COUPON_LOADING: 'COUPON_LOADING',
    COUPON_SUCCESS: 'COUPON_SUCCESS',
    COUPON_ERROR: 'COUPON_ERROR',
    SET_REFERRAL: 'SET_REFERRAL',
    INVOICES_LOADING: 'INVOICES_LOADING',
    INVOICES_SUCCESS: 'INVOICES_SUCCESS',
    INVOICES_ERROR: 'INVOICES_ERROR',
    INVOICE_PAYMENT_LOADING: 'INVOICE_PAYMENT_LOADING',
    INVOICE_PAYMENT_SUCCESS: 'INVOICE_PAYMENT_SUCCESS',
    INVOICE_PAYMENT_ERROR: 'INVOICE_PAYMENT_ERROR',
    CARDS_LOADING: 'CARDS_LOADING',
    CARD_ADD_SUCCESS: 'CARD_ADD_SUCCESS',
    CARD_ADD_RESET: 'CARD_ADD_RESET',
    CARDS_SUCCESS: 'CARDS_SUCCESS',
    CARDS_ERROR: 'CARDS_ERROR',
    CANCEL_ACCOUNT_LOADING: 'CANCEL_ACCOUNT_LOADING',
    CANCEL_ACCOUNT_SUCCESS: 'CANCEL_ACCOUNT_SUCCESS',
    CANCEL_ACCOUNT_ERROR: 'CANCEL_ACCOUNT_ERROR',
    ABORT_CANCEL_ACCOUNT_LOADING: 'ABORT_CANCEL_ACCOUNT_LOADING',
    ABORT_CANCEL_ACCOUNT_SUCCESS: 'ABORT_CANCEL_ACCOUNT_SUCCESS',
    ABORT_CANCEL_ACCOUNT_ERROR: 'ABORT_CANCEL_ACCOUNT_ERROR',
    PAUSE_ACCOUNT_LOADING: 'PAUSE_ACCOUNT_LOADING',
    PAUSE_ACCOUNT_SUCCESS: 'PAUSE_ACCOUNT_SUCCESS',
    PAUSE_ACCOUNT_ERROR: 'PAUSE_ACCOUNT_ERROR',
    BILLING_DETAILS_LOADING: 'BILLING_DETAILS_LOADING',
    BILLING_DETAILS_SUCCESS: 'BILLING_DETAILS_SUCCESS',
    BILLING_DETAILS_ERROR: 'BILLING_DETAILS_ERROR',
    CHANGE_BILLING_DETAILS_LOADING: 'CHANGE_BILLING_DETAILS_LOADING',
    CHANGE_BILLING_DETAILS_SUCCESS: 'CHANGE_BILLING_DETAILS_SUCCESS',
    CHANGE_BILLING_DETAILS_ERROR: 'CHANGE_BILLING_DETAILS_ERROR',
};

export const actions = {
    setReferralId: referral => async (dispatch, getState) => dispatch({ type: actionTypes.SET_REFERRAL, referral }),
    clearCharge: () => async (dispatch, getState) => dispatch({ type: actionTypes.CHARGE_RESET }),
    chargeUser: (token, chargeDetails) => async (dispatch, getState) => {
        if (!token || !chargeDetails) {
            return dispatch(actions.chargeUserError('Issue With Payment Provider'));
        }
        try {
            const {
                amount,
                description,
                plan,
                planId,
                name,
                isYearly,
                coupon,
                price,
                billingDetails,
            } = chargeDetails;
            const { settings, billing } = getState();
            const payload = {
                description,
                name,
                source: token.id,
                currency: "USD",
                amount,
                planId,
                coupon,
                metadata: {
                    plan: plan.toLowerCase(),
                    referral: billing.referral,
                    isYearly,
                },
                billingDetails: billingDetails || {},
                email: settings.email,
            };
            dispatch({ type: actionTypes.CHARGE_LOADING });
            const result = await fetch.put(`/billing/charge/`, payload);
            dispatch(actions.confirmCharge());
            dispatch(actions.chargeUserSuccess(result));
            tracker.purchase(price);
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch(actions.chargeUserError('There has been an issue while charging your card', e));
        }
    },
    upgradeUser: chargeDetails => async (dispatch, getState) => {
        try {
            if (!chargeDetails) {
                return dispatch(actions.chargeUserError('Issue With Payment Provider'));
            }
            const {
                amount,
                description,
                plan,
                planId,
                isYearly,
                coupon,
                price
            } = chargeDetails;
            const { settings } = getState();
            const { email, name, referral } = settings;
            const payload = {
                description,
                name,
                source: 'stripe',
                currency: "USD",
                amount,
                planId,
                coupon,
                metadata: {
                    plan: plan.toLowerCase(),
                    isYearly,
                    referral,
                },
                email,
            };
            dispatch({ type: actionTypes.CHARGE_LOADING });
            const result = await fetch.post(`/billing/charge/`, payload);
            dispatch(actions.confirmCharge());
            dispatch(actions.chargeUserSuccess(result));
            tracker.purchase(price);
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch(actions.chargeUserError(e));
        }
    },
    confirmCharge: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CONFIRM_CHARGE_LOADING });
            const { data: result } = await fetch.get(`/billing/charge/confirm/`);

            if (result.chargeFinalized) {
                return dispatch({ type: actionTypes.CONFIRM_CHARGE_SUCCESS });
            }
            return setTimeout(() => dispatch(actions.confirmCharge()), 2500);
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CONFIRM_CHARGE_ERROR });
        }
    },
    chargePaypal: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CONFIRM_CHARGE_LOADING });
            const result = await fetch.get(`/billing/charge/pp/`);
            // we do not support paypal now
            // dispatch({ type: actionTypes.CONFIRM_CHARGE_SUCCESS });
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CONFIRM_CHARGE_ERROR });
        }
    },
    chargeUserSuccess: data => async (dispatch, getState) => {
        if (!data) {
            return dispatch({ type: actionTypes.CHARGE_SUCCESS, invoiceUrl: null });
        }
        const invoiceUrl = data.data.receiptUrl;
        dispatch({ type: actionTypes.CHARGE_SUCCESS, invoiceUrl });
    },
    chargeUserError: data => async (dispatch, getState) => {
        dispatch({ type: actionTypes.CHARGE_ERROR });
        console.log(data);
        console.log('Payment Error');
    },
    applyCoupon: coupon => async (dispatch, getState) => {
        if (!coupon) {
            return dispatch(actions.chargeUserError('Please fill in your coupon'));
        }
        try {
            // debugger;
            dispatch({ type: actionTypes.COUPON_LOADING });
            const result = await fetch.get(`/billing/charge/coupon/${coupon}`);
            // debugger;
            const { referral, coupon: couponData } = result.data;
            dispatch({ type: actionTypes.COUPON_SUCCESS, referral, coupon: couponData });
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.COUPON_ERROR });
        }
    },
    fetchInvoices: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.INVOICES_LOADING });
            const result = await fetch.get(`/billing/details/invoices/`);
            const { invoices } = result.data;
            dispatch({ type: actionTypes.INVOICES_SUCCESS, invoices });
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.INVOICES_ERROR });
        }
    },
    payInvoice: invoiceId => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.INVOICE_PAYMENT_LOADING });
            const result = await fetch.post(`/billing/charge/invoices/${invoiceId}/`);
            dispatch({ type: actionTypes.INVOICE_PAYMENT_SUCCESS, result });
            message.success('Payment Successful');
            dispatch(SettingsActions.getUserData()); // this does not fetch the update user data
        } catch (e) {
            message.error(e.message);
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.INVOICE_PAYMENT_ERROR });
        }
    },
    fetchBillingDetails: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.BILLING_DETAILS_LOADING });
            const result = await fetch.get(`/billing/details/billing-details`);
            const { billingDetails } = result.data;
            dispatch({ type: actionTypes.BILLING_DETAILS_SUCCESS, billingDetails: billingDetails });
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.BILLING_DETAILS_ERROR });
        }
    },
    changeBillingDetails: values => async (dispatch, getState) => {
        try {
            console.log('changing billingDetails values', values);
            dispatch({ type: actionTypes.CHANGE_BILLING_DETAILS_LOADING });
            const result = await fetch.patch(`/billing/details/billing-details`, values);
            console.log(result);
            dispatch({ type: actionTypes.CHANGE_BILLING_DETAILS_SUCCESS });
            dispatch(actions.fetchBillingDetails());
            dispatch(SettingsActions.getUserData());
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CHANGE_BILLING_DETAILS_ERROR });
        }
    },
    fetchCards: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CARDS_LOADING });
            const result = await fetch.get(`/billing/details/cards`);
            const { cards } = result.data;
            dispatch({ type: actionTypes.CARDS_SUCCESS, cards });
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CARDS_ERROR });
        }
    },
    addCard: card => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CARDS_LOADING });
            const result = await fetch.put(`/billing/details/cards/`, card);
            console.log(result);
            dispatch({ type: actionTypes.CARD_ADD_SUCCESS });
            dispatch(actions.fetchCards());
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CARDS_ERROR });
        }
    },
    resetAddCard: () => async (dispatch, getState) => {
        dispatch({ type: actionTypes.CARD_ADD_RESET });
    },
    makeCardPrimary: cardId => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CARDS_LOADING });
            const result = await fetch.post(`/billing/details/cards/${cardId}`);
            console.log(result);
            dispatch(actions.fetchCards());
            dispatch(SettingsActions.getUserData())
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CARDS_ERROR });
            message.error('There has been a problem making this card primary')
        }
    },
    deleteCard: cardId => async (dispatch, getState) => {
        try {
            const result = await fetch.delete(`/billing/details/cards/${cardId}`);
            console.log(result);
            dispatch(actions.fetchCards());
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CARDS_ERROR });
        }
    },
    cancelAccount: reasons => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CANCEL_ACCOUNT_LOADING });
            const result = await fetch.post(`/billing/charge/cancel/`, reasons);
            console.log(result);
            dispatch({ type: actionTypes.CANCEL_ACCOUNT_SUCCESS });
            dispatch(SettingsActions.getUserData());
            history.push('/app/dashboard/');
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.CANCEL_ACCOUNT_ERROR });
        }
    },
    pauseAccount: (term, planId) => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.PAUSE_ACCOUNT_LOADING });
            const result = await fetch.post(`/billing/charge/pause/`, { term, planId });
            console.log(result);
            dispatch(SettingsActions.getUserData());
            dispatch({ type: actionTypes.PAUSE_ACCOUNT_SUCCESS });
            // history.push('/app/dashboard/');
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.PAUSE_ACCOUNT_ERROR });
        }
    },
    abortCancelAccount: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.ABORT_CANCEL_ACCOUNT_LOADING });
            const result = await fetch.put(`/billing/charge/cancel/`);
            console.log(result);
            dispatch({ type: actionTypes.ABORT_CANCEL_ACCOUNT_SUCCESS });
            dispatch(SettingsActions.getUserData());
            history.push('/app/dashboard/');
        } catch (e) {
            console.log('error', e);
            logger.logError(e);
            dispatch({ type: actionTypes.ABORT_CANCEL_ACCOUNT_ERROR });
        }
    },
};

export const getDefaultState = () => ({
    loading: false,
    success: false,
    error: false,

    confirmLoading: false,
    confirmSuccess: false,
    confirmError: false,

    couponLoading: false,
    couponSuccess: false,
    couponError: false,
    coupon: null,

    referral: null,
    products: {},

    invoiceUrl: null,

    key: getStripeKey(),

    invoices: [],
    invoicesLoading: false,
    invoicesSuccess: false,
    invoicesError: false,

    cards: [],
    cardsLoading: false,
    cardsError: false,
    cardsSuccess: false,
    cardAddSuccess: false,

    billingDetails: {},
    billingDetailsLoading: false,
    billingDetailsError: false,
    billingDetailsSuccess: false,

    changeBillingDetailsLoading: false,
    changeBillingDetailsError: false,
    changeBillingDetailsSuccess: false,

    cancelAccountLoading: false,
    cancelAccountError: false,
    cancelAccountSuccess: false,

    abortCancelAccountLoading: false,
    abortCancelAccountError: false,
    abortCancelAccountSuccess: false,
    
    pauseAccountLoading: false,
    pauseAccountError: false,
    pauseAccountSuccess: false,
});

export default handleActions({
    [actionTypes.CHARGE_RESET]: () => ({ ...getDefaultState() }),
    [actionTypes.SET_REFERRAL]: (state, { referral }) => ({ ...state, referral }),
    [actionTypes.CHARGE_LOADING]: state => ({ ...state, loading: true, error: false, success: false, isProcessing: true, }),
    [actionTypes.CHARGE_SUCCESS]: (state, { invoiceUrl }) => ({ ...state, loading: false, success: true, error: false, invoiceUrl }),
    [actionTypes.CHARGE_ERROR]: (state) => ({ ...state, loading: false, success: false, error: true }),
    [actionTypes.CONFIRM_CHARGE_LOADING]: state => ({ ...state, confirmLoading: true, confirmError: false, confirmSuccess: false, }),
    [actionTypes.CONFIRM_CHARGE_SUCCESS]: state => ({ ...state, confirmLoading: false, confirmError: false, confirmSuccess: true, }),
    [actionTypes.CONFIRM_CHARGE_ERROR]: state => ({ ...state, confirmLoading: false, confirmError: true, confirmSuccess: false, }),
    [actionTypes.COUPON_LOADING]: state => ({ ...state, couponLoading: true, couponError: false, couponSuccess: false }),
    [actionTypes.COUPON_SUCCESS]: (state, { coupon, referral }) => {
        return ({ ...state, couponLoading: false, couponSuccess: true, couponError: false, coupon, referral: referral || state.referral });
    },
    [actionTypes.COUPON_ERROR]: (state) => {
        return ({ ...state, couponLoading: false, couponSuccess: false, couponError: true });
    },
    [actionTypes.INVOICES_LOADING]: state => ({ ...state, invoicesLoading: true, invoicesError: false, invoicesSuccess: false }),
    [actionTypes.INVOICES_SUCCESS]: (state, { invoices }) => {
        return ({ ...state, invoicesLoading: false, invoicesError: true, invoicesSuccess: false, invoices });
    },
    [actionTypes.INVOICES_ERROR]: (state) => {
        return ({ ...state, invoicesLoading: false, invoicesError: true, invoicesSuccess: false });
    },
    [actionTypes.INVOICE_PAYMENT_LOADING]: state => ({ ...state, invoicePaymentLoading: true, invoicePaymentError: false, invoicePaymentSuccess: false }),
    [actionTypes.INVOICE_PAYMENT_SUCCESS]: (state) => {
        return ({ ...state, invoicePaymentLoading: false, invoicePaymentError: true, invoicePaymentSuccess: false });
    },
    [actionTypes.INVOICE_PAYMENT_ERROR]: (state) => {
        return ({ ...state, invoicePaymentLoading: false, invoicePaymentError: true, invoicePaymentSuccess: false });
    },
    [actionTypes.CARDS_LOADING]: state => ({ ...state, cardsLoading: true, cardsError: false, cardsSuccess: false }),
    [actionTypes.CARDS_SUCCESS]: (state, { cards }) => {
        return ({ ...state, cardsLoading: false, cardsError: false, cardsSuccess: true, cards });
    },
    [actionTypes.CARD_ADD_SUCCESS]: (state, { cards }) => {
        return ({ ...state, cardsLoading: false, cardsError: false, cardAddSuccess: true, });
    },
    [actionTypes.CARD_ADD_RESET]: (state) => {
        return ({ ...state, cardsLoading: false, cardsError: false, cardAddSuccess: false });
    },
    [actionTypes.CARDS_ERROR]: (state) => {
        return ({ ...state, cardsLoading: false, cardsError: true, cardsSuccess: false });
    },
    [actionTypes.BILLING_DETAILS_LOADING]: state => ({ ...state, billingDetailsLoading: true, billingDetailsError: false, billingDetailsSuccess: false }),
    [actionTypes.BILLING_DETAILS_SUCCESS]: (state, { billingDetails }) => {
        return ({ ...state, billingDetailsLoading: false, billingDetailsError: false, billingDetailsSuccess: false, billingDetails });
    },
    [actionTypes.BILLING_DETAILS_ERROR]: (state) => {
        return ({ ...state, billingDetailsLoading: false, billingDetailsError: true, billingDetailsSuccess: false });
    },
    [actionTypes.CHANGE_BILLING_DETAILS_LOADING]: state => ({ ...state, changeBillingDetailsLoading: true, changeBillingDetailsError: false, changeBillingDetailsSuccess: false }),
    [actionTypes.CHANGE_BILLING_DETAILS_SUCCESS]: (state) => {
        return ({ ...state, changeBillingDetailsLoading: false, changeBillingDetailsError: false, changeBillingDetailsSuccess: true });
    },
    [actionTypes.CHANGE_BILLING_DETAILS_ERROR]: (state) => {
        return ({ ...state, changeBillingDetailsLoading: false, changeBillingDetailsError: true, changeBillingDetailsSuccess: false });
    },
    [actionTypes.CANCEL_ACCOUNT_LOADING]: state => ({ ...state, cancelAccountLoading: true, cancelAccountError: false, cancelAccountSuccess: false }),
    [actionTypes.CANCEL_ACCOUNT_SUCCESS]: state => ({ ...state, cancelAccountLoading: false, cancelAccountError: false, cancelAccountSuccess: true }),
    [actionTypes.CANCEL_ACCOUNT_ERROR]: state => ({ ...state, cancelAccountLoading: false, cancelAccountError: true, cancelAccountSuccess: false }),
    [actionTypes.ABORT_CANCEL_ACCOUNT_LOADING]: state => ({ ...state, abortCancelAccountLoading: true, abortCancelAccountError: false, abortCancelAccountSuccess: false }),
    [actionTypes.ABORT_CANCEL_ACCOUNT_SUCCESS]: state => ({ ...state, abortCancelAccountLoading: false, abortCancelAccountError: false, abortCancelAccountSuccess: true }),
    [actionTypes.ABORT_CANCEL_ACCOUNT_ERROR]: state => ({ ...state, abortCancelAccountLoading: false, abortCancelAccountError: true, abortCancelAccountSuccess: false }),
    [actionTypes.PAUSE_ACCOUNT_LOADING]: state => ({ ...state, pauseAccountLoading: true, pauseAccountError: false, pauseAccountSuccess: false }),
    [actionTypes.PAUSE_ACCOUNT_SUCCESS]: state => ({ ...state, pauseAccountLoading: false, pauseAccountError: false, pauseAccountSuccess: true }),
    [actionTypes.PAUSE_ACCOUNT_ERROR]: state => ({ ...state, pauseAccountLoading: false, pauseAccountError: true, pauseAccountSuccess: false }),
}, getDefaultState());