import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from "@sentry/react";

import crashReporter from './middlewares/crashReporter';

import SettingsReducer from './SettingsReducer';
import BillingReducer from './BillingReducer';
import OrdersReducer from './OrdersReducer';
import SMSReducer from './SMSReducer';
import ProductDetailsReducer from './ProductDetailsReducer';

const rootReducer = () => combineReducers({
    settings: SettingsReducer,
    billing: BillingReducer,
    orders: OrdersReducer,
    sms: SMSReducer,
    productDetails: ProductDetailsReducer,
  });

const createReduxStore = (makeRootReducer = rootReducer, additionalMiddlewares = []) => {
    const middleware = [
        thunk,
        process.env.NODE_ENV === 'development' && createLogger({ collapsed: true }),
        crashReporter,
        ...additionalMiddlewares,
    ].filter(mw => !!mw);

    const initialState = {};

    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        stateTransformer: state => {
            const transformedState = {
                ...state,
                notifications: null,
                sites: null,
                jobs: null,
                analytics: null,
                products: null,
                globalModals: null,
            };
            return transformedState;
          },
    });

    const enhancers = [sentryReduxEnhancer];

    const store = createStore(
        makeRootReducer(),
        initialState,
        compose(
            applyMiddleware(...middleware),
            ...enhancers,
        ),
    );

    return store;
};

export default createReduxStore;
