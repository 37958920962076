import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { MailOutlined, LockOutlined, ThunderboltOutlined, GoogleOutlined, LoadingOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

import { actions as SettingsReducer } from '../../store/SettingsReducer';

import history from '../../history';

import { Form, Input, Button, Card, Divider } from 'antd';

import "./Signup.css";

class LoginForm extends Component {
    handleSubmit = values => {
        const { onSubmit } = this.props;
        const { siteUrl, sitePrefix, ...rest } = values;
        onSubmit({ ...rest, siteUrl: `${sitePrefix}${siteUrl}/` });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        const { signupLoading } = this.props;

        const formItemLayout = {
            labelCol: {
                span: 7,
            },
            wrapperCol: {
                span: 17,
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };

        return (
            <Form
                {...formItemLayout}
                onFinish={this.handleSubmit}
                onFinishFailed={this.onFinishFailed}
                className="login-form"
                style={{ textAlign: 'left' }}
            >
                <Form.Item label="Email" name="email" className="login-form-item" rules={[{ required: true, message: 'Please input your email!' }]}>
                    <Input
                        className="login-form-input"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item label="Password" name="password" className="login-form-item" rules={[{ required: true, message: 'Please input your Password!' }]}>
                    <Input
                        className="login-form-input"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item className="login-form-item" {...tailFormItemLayout}>
                    <Button
                        className="login-form-input"
                        style={{ width: '100%', marginTop: '10px' }}
                        icon={signupLoading ? <LoadingOutlined /> : <ThunderboltOutlined />}
                        type="primary"
                        htmlType="submit"
                        disabled={signupLoading}
                    >
                        Create Account
          </Button>
                </Form.Item>
            </Form>
        );
    }
}

class Signup extends Component {
    state = { siteUrl: '', inviteId: '' }
    componentWillMount() {
        document.title = 'Signup | Zakluchator App';
        if (this.props.settings.userId) return history.push('/app/dashboard/');
        const { search } = this.props.location;
        // console.log(this.props);
        const parsed = queryString.parse(search);

        console.log(parsed);
        const { inviteId, siteUrl, campaign } = parsed;
        this.setState({ inviteId, siteUrl, campaign });
    }

    componentDidUpdate() {
        if (this.props.settings.userId) return history.push('/app/dashboard/');
    }

    mergeQueryString = (values = {}) => {
        const { inviteId, siteUrl, campaign } = this.state;
        const userValues = {
            ...values,
            inviteId,
            siteUrl,
            campaign,
        };
        return userValues;
    }

    signupUser = values => {
        this.props.signupUserApi(this.mergeQueryString(values));
    }

    signupGoogle = () => {
        this.props.loginUserGoogle(this.mergeQueryString());
    }

    render() {
        const { siteUrl, inviteId } = this.state;
        const { signupLoading } = this.props.settings;
        return (
            <div className="signup-form">
                <Card
                    title={<div style={{ textAlign: 'center' }}>Create Your Account</div>}
                    //  title='Create Your Account'
                    // title={`Start Your ${inviteId ? '30' : 7} Day Free Trial`}
                    className="signup-card"
                    headStyle={{ fontSize: 20 }}
                >
                    <LoginForm
                        siteUrl={siteUrl}
                        onSubmit={this.signupUser}
                        loginUser={this.loginUser}
                        signupLoading={signupLoading}
                    />
                    <Divider>OR</Divider>
                    <Button
                        style={{ width: '100%' }}
                        icon={signupLoading ? <LoadingOutlined /> : <GoogleOutlined />}
                        size="large"
                        onClick={this.signupGoogle}
                        disabled={signupLoading}
                    >
                        Sign Up With Google
          </Button>
                </Card>
                <div>Already have an account? <Link to="/login/">Log in</Link></div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Signup);