import firebase from 'firebase';

const firebaseConfigProduction = {
    apiKey: process.env.REACT_APP_FIREBASE_1_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_1_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_1_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_1_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_1_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_1_APP_ID,
};

const fire = firebase.initializeApp(firebaseConfigProduction);

export default fire;