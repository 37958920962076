import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter, matchPath, Route, Switch } from "react-router-dom";
import { Menu, Typography, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { actions as SettingsActions } from '../../store/SettingsReducer';
import { actions as BillingActions } from '../../store/BillingReducer';

import Profile from './Profile/Profile';
import Billing from './Billing/Billing';
import Connection from './Connection/Connection';
import Team from './Team/Team';
import Subscription from './Subscription/Subscription';
import Integrations from './Integrations/Integrations';

import './Account.css';

const { Title } = Typography;

const routes = [
    {
        path: "/app/account/profile",
        exact: true,
        sidebar: () => <Link to="/app/account/profile">Profile</Link>,
        main: (props) => <Profile {...props} />
    },
    {
        path: "/app/account/connection",
        exact: true,
        sidebar: () => <Link to="/app/account/connection">Connection</Link>,
        main: (props) => <Connection {...props} />,
    },
    {
        path: "/app/account/team",
        exact: true,
        sidebar: () => <Link to="/app/account/team">Team</Link>,
        main: (props) => <Team {...props} />,
    },
    {
        path: "/app/account/subscription",
        exact: true,
        sidebar: () => <Link to="/app/account/subscription">Subscription</Link>,
        main: (props) => <Subscription {...props} />,
    },
    {
        path: "/app/account/billing",
        exact: true,
        sidebar: () => <Link to="/app/account/billing">Billing</Link>,
        main: (props) => <Billing {...props} />
    },
    {
        path: "/app/account/integrations",
        exact: true,
        sidebar: () => <Link to="/app/account/integrations">Integrations</Link>,
        main: (props) => <Integrations {...props} />,
    },
];

class Sider extends React.Component {
    getActiveRoute = () => {
        try {
            const currentRoute = routes.find(p => matchPath(this.props.location.pathname, {
                path: p.path,
                exact: p.exact,
                strict: p.strict,
            }));
            return currentRoute.path;
        } catch (e) {
            console.log('Can`t find path');
            // message.info('Page unavailable,');
            return null;
        }
    }

    render() {
        const activeRoutePath = this.getActiveRoute();
        return (
            <Menu defaultSelectedKeys={[activeRoutePath]} selectedKeys={[activeRoutePath]} onClick={this.onMenuSelect} mode="inline" style={{ width: 300, textAlign: 'left' }}>
                <Menu.ItemGroup
                    key="g1"
                    title={
                        <span>
                            <UserOutlined />
                            <span style={{ marginLeft: '10px' }}>Account & Settings</span>
                        </span>
                    }>
                    {routes.filter(r => r.sidebar).map((r, i) => (
                        <Menu.Item key={r.path}>
                            <r.sidebar />
                        </Menu.Item>
                    ))}
                </Menu.ItemGroup>
            </Menu>
        )
    }
}


class Account extends Component {
    componentDidMount() {
        document.title = 'Account Settings | AMZ Watcher App';
    }

    render() {
        return (
            <div className="account-container">
                <Title style={{ textAlign: 'left' }}>Settings</Title>
                <div className="account-menuform">
                    <Sider {...this.props} />
                    <div className="account-form">
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={<route.main {...this.props} />}
                                />
                            ))}
                            <Route to={routes[0].path} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsActions,
    ...BillingActions,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Account);