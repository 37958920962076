import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Table, Badge, List } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import _ from 'lodash';

class ProductsTable extends Component {
    getColumns = () => {
        return [
            {
                title: 'Назва',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
                render: (url) => (
                  <a href={url} target="_blank" rel="noopener noreferrer" onClick={(e) => e.stopPropagation()}>
                    {url.length > 50 ? `${url.substring(0, 50)}...` : url}
                  </a>
                ),
            },
              
            {
                title: 'Category',
                dataIndex: 'category',
                key: 'cagegory',
                render: (one, two) => one.join(' >'),
            },
            {
                title: 'Characteristics',
                dataIndex: 'characteristics',
                key: 'characteristics',
                render: (one, two) => Object.keys(one).length,
            },
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
            },
            // {
            //     title: 'Статус',
            //     dataIndex: 'description',
            //     key: 'description',
            //     render: (one, two) => two.agent.full.legalTitle,
            // },
            
            // {
            //     title: 'Дії',
            //     dataIndex: 'Actions',
            //     key: 'actions',
            //     render: (one, two) => {
            //         return (
            //         <div style={{ width: 300 }}>
            //             <Button onClick={() => this.props.showAgentModal(two.id)} style={{ marginRight: 10 }}>Накладная</Button>
            //             <Button onClick={() => this.props.showSMSModal(two.id)} style={{ marginRight: 10 }}>Отправка СМС</Button>
            //             {/* <Popconfirm
            //                     title="Are you sure?"
            //                     onConfirm={() => this.props.onRemove(two._id)}
            //                     okText="Yes"
            //                     cancelText="No"
            //                 >
            //                    <Button type="secondary" icon={<CloseOutlined />}>Remove</Button>
            //                 </Popconfirm> */}
            //         </div>
            //         )
            //     },
            // }
        ];
    }

    expandedRowRender = record => {
        return (
            <div>
                <List
                    header={<div>Характеристики</div>}
                    size="small"
                    bordered
                    dataSource={Object.entries(record.characteristics)}
                    renderItem={(item) => (
                    <List.Item>
                        <b style={{ width: 300, display: 'inline-block' }}>{item[0]}:</b> {item[1]}
                    </List.Item>
                    )}
                    />
                <ReactJson src={record}  />
            </div>
        )
    }

    render() {
        const { data, isLoading } = this.props;
        // const tableData = _.sortBy(data, 'createdAt');
        // console.log('about to render table with data', tableData);
       
        return (
            <Table
                dataSource={data}
                columns={this.getColumns()}
                loading={isLoading}
                pagination={{ pageSize: 100, hideOnSinglePage: true }}
                rowKey='_id'
                size="small"
                expandable={{
                    expandedRowRender: this.expandedRowRender
                }}
            />
        ) 
    }
}

export default ProductsTable;
