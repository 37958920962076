import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { MailOutlined, LockOutlined, ThunderboltOutlined, GoogleOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

import history from '../../history';

import { actions as SettingsReducer } from '../../store/SettingsReducer';

import { Form, Input, Button, Card, Divider } from 'antd';

const formItemLayout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 0,
        },
    },
};

class LoginForm extends Component {
    handleSubmit = values => {
        const { onSubmit } = this.props;
        onSubmit(values);
    };

    render() {
        return (
            <Form onFinish={this.handleSubmit} className="login-form" {...formItemLayout}>
                <Form.Item label="Email" name="email" className="login-form-item" rules={[{ required: true, message: 'Please input your email' }]}>
                    <Input
                        className="login-form-input"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item label="Password" name="password" className="login-form-item" rules={[{ required: true, message: 'Please input your Password' }]}>
                    <Input
                        className="login-form-input"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"

                    />
                </Form.Item>
                <Form.Item className="login-form-item" {...tailFormItemLayout}>
                    <Button
                        style={{ width: '100%', marginTop: '10px' }}
                        icon={<ThunderboltOutlined />}
                        type="primary"
                        htmlType="submit"
                    >
                        Sign In
          </Button>
                </Form.Item>
            </Form>
        );
    }
}

class ResetPasswordForm extends Component {
    handleSubmit = values => {
        const { onSubmit } = this.props;
        onSubmit(values);
    };

    render() {
        return (
            <Form onFinish={this.handleSubmit} className="login-form" {...formItemLayout} style={{ textAlign: 'left' }}>
                <Form.Item className="login-form-item" label="Email" name="email" rules={[{ required: true, message: 'Please input your email' }]}>
                    <Input
                        className="login-form-input"
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item className="login-form-item" {...tailFormItemLayout}>
                    <Button
                        style={{ width: '100%', marginTop: '10px' }}
                        className="login-form-input"
                        type="primary"
                        htmlType="submit"
                    >
                        Reset Password
          </Button>
                </Form.Item>
            </Form>
        );
    }
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResetPassword: false,
        }
    }

    componentWillMount() {
        document.title = 'Login | Zakluchator App';
        // debugger
        if (this.props.settings.userId) return history.push('/app/dashboard/');
    }

    componentDidUpdate() {
        // debugger
        if (this.props.settings.userId) return history.push('/app/dashboard/');
    }

    loginUser = values => {
        this.props.loginUser(values);
    }

    loginUserGoogle = () => {
        this.props.loginUserGoogle();
    }

    resetPassword = values => {
        this.props.resetPassword(values);
    }

    render() {
        const { isResetPassword } = this.state;
        return (
            <div className="signup-form">
                {/* <img src={logo} alt="logo" width="200px" style={{ marginBottom: '20px' }} /> */}
                {!isResetPassword && (
                    <Card
                        title={<div style={{ textAlign: 'center' }}>Log In</div>}
                        className="signup-card"
                        headStyle={{ fontSize: 20 }}
                    >
                        <LoginForm
                            onSubmit={this.loginUser}
                            onResetPassword={() => this.setState({ isResetPassword: true })}
                        />
                        <Button
                            style={{ width: '100%' }}
                            className="login-form-input"
                            type="dashed"
                            onClick={() => this.setState({ isResetPassword: true })}
                        >
                            Forgot Password
                        </Button>
                        <Divider>OR</Divider>
                        <Button
                            style={{ width: '100%' }}
                            icon={<GoogleOutlined />}
                            size="large"
                            onClick={this.loginUserGoogle}>
                            Log In With Google
                        </Button>
                    </Card>)}
                {isResetPassword && (
                    <Card
                        title="Reset Password"
                        className="signup-card"
                        headStyle={{ fontSize: 20 }}
                    >
                        <ResetPasswordForm
                            onSubmit={this.resetPassword}
                        />
                    </Card>)}
                <div>Do not have an account yet? <Link to="/sign-up/">Sign Up</Link></div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);