import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Button, Table, Badge, Popconfirm, Statistic } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import ReactJson from 'react-json-view'
import _ from 'lodash'

class OrdersDeltaTable extends Component {
  getColumns = () => {
    return [
      {
        title: 'Номер Замовлення',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Дата',
        dataIndex: 'moment',
        key: 'moment',
      },
      {
        title: 'Сума',
        dataIndex: 'sum',
        key: 'sum',
        render: (one, two) => two.sum / 100,
      },
      {
        title: 'Profit abs',
        dataIndex: 'printed',
        key: 'profit',
        render: (one, two) => Math.ceil(two.profit.profitAbs) / 100,
      },
      {
        title: 'Profit %',
        dataIndex: 'printed',
        key: 'profit',
        render: (one, two) => `${Math.floor(two.profit.profitPerc * 10000) / 100}%`,
      },
      // {
      //   title: `Імʼя`,
      //   dataIndex: 'description',
      //   key: 'description',
      //   // render: (one, two) => two.agent.full.name || two.agent.full.legalTitle,
      // },
      // {
      //   title: 'Напечатано',
      //   dataIndex: 'printed',
      //   key: 'printed',
      // },
      {
        title: 'Напечатано',
        dataIndex: 'printed',
        key: 'printed',
        render: (one, two) => (two.printed ? 'так' : 'ні'),
      },
      {
        title: 'Проведено',
        dataIndex: 'applicable',
        key: 'applicable',
        render: (one, two) => (two.applicable ? 'так' : 'ні'),
      },

      // {
      //     title: 'Отгружено',
      //     dataIndex: 'description',
      //     key: 'description',
      //     render: (one, two) => two.agent.full.phone,
      // },
      // {
      //     title: 'Статус',
      //     dataIndex: 'description',
      //     key: 'description',
      //     render: (one, two) => two.agent.full.legalTitle,
      // },
    ]
  }

  expandedRowRender = (record) => {
    // debugger
    return (
      <div style={{ maxWidth: '1200px' }}>
        <ReactJson src={record} collapsed={true} />
      </div>
    )
  }

  render() {
    const { data, isLoading } = this.props
    const tableData = _.sortBy(data, 'date')
    // console.log('about to render table with data', tableData);

    return (
      <Table
        dataSource={tableData}
        columns={this.getColumns()}
        loading={isLoading}
        pagination={{ pageSize: 20, hideOnSinglePage: true }}
        rowKey="id"
        size="small"
        expandedRowRender={this.expandedRowRender}
      />
    )
  }
}

export default OrdersDeltaTable
