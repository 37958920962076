import React, { Component, Fragment } from 'react';
import { Button, Input, Typography, Breadcrumb, message, Tag } from 'antd';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import history from '../../history';

import DashboardTable from './DashboardTable';

const { Title } = Typography;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { showAddSitesModal: true };
    }

    componentDidMount() {
        document.title = 'Dashboard | Liliya';
        // const projectId = this.props.match.params.projectId;
        // this.props.fetchProjects();
        // this.props.fetchSites(projectId);
    }

    render() {
        return (
            <div>
                <div>
                    <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>Welcome</Title>
                </div>
                {/* <Button onClick={() => history.push('/app/orders/')}>See Orders</Button> */}
                {/* <div>
                    <div style={{ margin: '10px 0' }}>
                        <span style={{ marginRight: '15px' }}>Filter:</span>
                        <Button onClick={() => this.props.fetchSites(this.props.match.params.projectId)} style={{ marginRight: '5px' }}>Refresh</Button>
                        <Button onClick={this.createSite}>Create Site</Button>
                    </div>
                    <DashboardTable
                        data={currentProjectSites}
                    />
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Dashboard);