import React, { Component } from 'react';
import { Form, Input, Button, Card } from 'antd';

class EditProfileForm extends Component {
    handleSubmit = ({ name }) => {
        const { updateUser } = this.props;
        updateUser({ name });
    };

    render() {
        const { name, email, updateLoading } = this.props.settings;

        const formItemLayout = {
            labelCol: {
                xs: { span: 7 },
                sm: { span: 7 },
            },
            wrapperCol: {
                xs: { span: 10 },
                sm: { span: 10 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 10,
                    offset: 7,
                },
                sm: {
                    span: 10,
                    offset: 7,
                },
            },
        };

        return (
            <div style={{ textAlign: 'left' }}>
                <Card title={<span>Personal Information</span>}>
                    <Form {...formItemLayout} layout="horizontal" onFinish={this.handleSubmit} initialValues={{ name, email }}>
                        <Form.Item name='name' label="Name" rules={[{ required: true, message: 'Please input your name' }]}>
                            <Input
                                className="account-form-input"
                                placeholder="Name"
                            />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" loading={updateLoading} disabled={updateLoading}>
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}

export default EditProfileForm;