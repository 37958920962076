import React, { Component } from 'react';
import { Form, Input, Button, Card } from 'antd';

class ChangePasswordForm extends Component {
    handleSubmit = values => {
        const { updatePassword } = this.props;
        const { oldPassword, newPassword } = values;
        updatePassword({ oldPassword, newPassword });
    };

    passwordValidator = ({ getFieldValue }) => ({
        validator(rule, value) {
            if (value && value.length < 8) {
                return Promise.reject('Passwords should be at least 8 symbols');
            }
            return Promise.resolve();
        },
    });

    confirmPasswordValidator = ({ getFieldValue }) => ({
        validator(rule, value) {
            if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
            }
            return Promise.reject('Passwords should match');
        },
    });

    render() {
        const { settings } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 7 },
                sm: { span: 7 },
            },
            wrapperCol: {
                xs: { span: 10 },
                sm: { span: 10 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 10,
                    offset: 7,
                },
                sm: {
                    span: 10,
                    offset: 7,
                },
            },
        };
        const isSSO = settings.provider !== 'password';

        return (
            <div style={{ textAlign: 'left', width: 600 }}>
                <Card title={<span>Password</span>} style={{ marginTop: '20px' }}>
                    {isSSO && <div>Changing your password is not allowed for Signle-Sign-On accounts. (Google, Twitter, Facebook, etc.)</div>}
                    {!isSSO && (<Form {...formItemLayout} layout="horizontal" onFinish={this.handleSubmit}>
                        <Form.Item
                            label="Old Password"
                            rules={[{ required: true, message: 'Please input your old password' }]}
                            name='oldPassword'
                        >
                            <Input
                                className="account-form-input"
                                placeholder="Old Password"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item
                            label="New Password"
                            rules={[{ required: true, message: 'Please input your new password' }, this.passwordValidator]}
                            name='newPassword'
                            hasFeedback
                        >
                            <Input
                                className="account-form-input"
                                placeholder="New Password"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Confirm Password"
                            rules={[{ required: true, message: 'Please confirm your new password' }, this.confirmPasswordValidator]}
                            dependencies={['newPassword']}
                            name="confirmPassword"
                            hasFeedback
                        >
                            <Input
                                className="account-form-input"
                                placeholder="Confirm Your Password"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" loading={settings.updateLoading} disabled={settings.updateLoading}>
                                Save
                            </Button>
                        </Form.Item>
                    </Form>)}
                </Card>
            </div>
        );
    }
}

export default ChangePasswordForm;