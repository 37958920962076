import React, { Component } from 'react';
import { Layout, Menu, Icon, Breadcrumb } from 'antd';
import { Router, Route, Redirect, Switch, Link } from "react-router-dom";
import { Provider } from 'react-redux';
import history from './history';

import createStore from './store/createStore';

import PrivateRoute from './auth/PrivateRoute';
import AuthenticationMonitor from './auth/AuthMonitor';

import Login from './Pages/Auth/Login';
import Logout from './Pages/Auth/Logout';
import Signup from './Pages/Auth/Signup';
import VerifyEmail from './Pages/Auth/VerifyEmail';
import AccountInactive from './Pages/Auth/AccountInactive';

import Account from './Pages/Account/Account';

import Dashboard from './Pages/Dashboard/Dashboard';
import Orders from './Pages/Orders/Orders';
import Order from './Pages/Orders/Order';
import Search from './Pages/Orders/Search';

import Header from './components/Header/Header';

import './App.css';
import ProductDetails from './Pages/ProductDetails/ProductDetails';
import OrdersDelta from './Pages/OrdersDelta/OrdersDelta';

const { Content } = Layout;

const store = createStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <Router history={history}>
          <AuthenticationMonitor>
            <Layout style={{ minHeight: '100vh' }}>
              <Header />
              <Layout style={{ background: '#fff' }}>
                <Layout style={{ minWidth: '1284px', margin: '0 auto', background: '#fff' }}>
                  <Content className="app-content" style={{ margin: '0 16px' }}>
                    <Switch>
                        <Route path="/" exact component={props => <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>} />
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Logout} />
                        <Route exact path="/sign-up" component={Signup} />
                        <Route exact path="/verify" component={VerifyEmail} />
                        <Route exact path="/verify/:userId/:emailVerifyId" component={VerifyEmail} />
                        <Route path="/inactive" component={AccountInactive} />

                        <PrivateRoute path="/app/dashboard" exact component={Dashboard} />
                        <PrivateRoute path="/app/orders" exact component={Orders} />
                        <PrivateRoute path="/app/orders/search" exact component={Search} />
                        <PrivateRoute path="/app/order/:orderId" exact component={Order} />
                        <PrivateRoute path="/app/product-details" exact component={ProductDetails} />
                        <PrivateRoute path="/app/orders-delta" exact component={OrdersDelta} />

                        <PrivateRoute path="/app/account" component={Account} />

                        <Redirect to="/app/dashboard" />
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
            </AuthenticationMonitor>
          </Router>
        </div>
      </Provider>
    );
  }
}

export default App;