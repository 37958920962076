import React from 'react';
import { Card, Spin, Button, Row, Col, List } from 'antd';
import { CardElement } from 'react-stripe-elements';
import { LockOutlined, LockFilled, LoadingOutlined, TagOutlined, CheckOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import BillingDetailsForm from './BillingInformationForm';

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize: '13px',
                color: '#424770',
                // letterSpacing: '0.025em',
                fontFamily: 'Open Sans, sans-serif',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding: '10px 16px',
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

export const CreditCardInputForm = props => {
    const { handleSubmit, formRef, validationErrors } = props;
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '15px' }}>Payment Method</div>
            <div style={{ margin: '10px 0 15px 0' }}>
                <form onSubmit={handleSubmit} data-rewardful ref={formRef} width="200" height="200">
                    <label style={{ marginBottom: '10px', display: 'block' }} className="checkout-field">
                        <input
                            className={validationErrors.cardNameError ? 'checkout-field-error' : ''}
                            name="name"
                            type="text"
                            placeholder="Cartholder Name"
                            style={{ width: '100%', padding: '8px 16px', fontSize: '13px' }}
                            required
                        />
                        {validationErrors.cardNameError && <span className="modal-error modal-error-field">This field is required</span>}
                    </label>
                    <label className="checkout-field">
                        <CardElement {...createOptions()} />
                    </label>
                </form>
            </div>
            <span style={{ color: 'grey', fontSize: '12px', display: 'block' }}>
                <LockOutlined /> Your payment information is safely encrypted and stored by our partner <a href="https://stripe.com/docs/security/stripe" target="_blank" style={{ color: 'grey', textDecoration: 'underline' }}>Stripe</a>.
            </span>
        </React.Fragment>
    )
}

export const validateCreditCardInputForm = (ref, saveError) => {
    const node = ref.current;
    let cardNameError = false; // this is false by default so that the form works during first purchase
    try {
        cardNameError = (node[0] && node[0].value) ? false : true;
    } catch (e) {
        console.log(e);
    }

    saveError({ cardNameError })

    const isFormValid = !cardNameError;

    return isFormValid;
}

export const StripeCheckout = props => {
    const { isCouponFieldOpen, toggleCouponField, isBillingOpen, toggleBillingDetails } = props;
    const { hasCreditCard, hasActiveSubscription } = props.settings;

    return (
        <Card style={{ boxShadow: '0 2px 11px -4px rgba(0,23,62,0.2), 0 0 1px 0 #a8b9d5', marginTop: 10 }} bodyStyle={{ padding: '30px 28px 28px' }}>
            {!hasCreditCard && <CreditCardInputForm  {...props} />}
            {false && !hasCreditCard && <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                <span
                    style={{ color: 'grey', fontSize: '13px', display: 'block', cursor: 'pointer' }}
                    onClick={toggleBillingDetails}
                >
                    {isBillingOpen ? `Hide` : `Show`} billing details {isBillingOpen ? <UpOutlined style={{ fontSize: 11 }} /> : <DownOutlined style={{ fontSize: 11 }} />}
                </span>
                <span></span>
            </div>
            }
            {!hasCreditCard && isBillingOpen && <BillingDetails {...props} />}
            <ContunueButton {...props} />
            {!hasActiveSubscription && isCouponFieldOpen && <Coupon {...props} />}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: 'grey', fontSize: '13px', display: 'block' }}><LockOutlined /> Secured By Stripe</span>
            </div>
        </Card>
    )
}

export const ContunueButton = props => {
    const { billing, formError, couponName, isPaymentLoading, handleSubmit, buttonText = 'Pay and Subscribe' } = props;
    const { loading, cardsLoading, couponLoading, coupon, couponSuccess } = billing;

    const hasNotValidatedCoupon = couponName && couponName.length > 0 && !coupon && !couponSuccess && !couponLoading;
    const isLoading = isPaymentLoading || loading || cardsLoading;

    return (
        <React.Fragment>
            <Button
                className="core-optin-cta complete-checkout"
                style={{ width: '100%' }}
                onClick={handleSubmit}
                disabled={isLoading || hasNotValidatedCoupon}
            >
                {!isLoading && <span><LockFilled /> &nbsp; {buttonText}</span>}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />}
            </Button>
            {billing.error && <span className="modal-error">There has been a problem charging your card. Please try again or contact support</span>}
            {formError && <span className="modal-error">{formError}</span>}
        </React.Fragment>
    );
}

export const PriceBreakdown = props => {
    const { plan, billing } = props;
    const couponOff = billing.coupon ? billing.coupon.percent_off : 0;
    return (
        <Row style={{ margin: '20px 0', fontSize: '15px' }}>
            <Col span={12}>
                <span style={{ textAlign: 'left', fontWeight: 600 }}>Plan Price</span>
            </Col>
            <Col span={12}>
                <span style={{ textAlign: 'right', width: '100%', display: 'block', paddingRight: '10px' }}>
                    $ {(plan.price / 100 * (1 - couponOff / 100)).toFixed(2)}
                </span>
                <span style={{ textAlign: 'right', width: '100%', display: 'block', paddingRight: '10px', fontWeight: 600, color: '#262626' }}>
                    {couponOff ? `${couponOff} % OFF Applied` : ''}
                </span>
            </Col>
        </Row>
    )
}

export const Coupon = props => {
    const { billing, settings, couponName, handleApplyCoupon, handleCouponChange, toggleCouponField } = props;
    const { loading, couponLoading, coupon, couponSuccess } = billing;

    return (
        <div style={{ margin: '0 0 10px 0' }}>
            <span style={{ textAlign: 'left', fontWeight: '600', fontSize: '15px' }}>Coupon Code</span>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label className="checkout-field" style={{ width: '70%', margin: '0 10px 0 0' }}>
                    <input
                        name="coupon"
                        type="text"
                        placeholder="Coupon code"
                        value={couponName}
                        onChange={handleCouponChange}
                        style={{ width: '100%', margin: '10px 0', padding: '4px 10px', fontSize: '14px' }}
                    />
                </label>
                <Button
                    className="apply-coupon-button"
                    onClick={handleApplyCoupon}
                    disabled={couponLoading || couponSuccess}
                >
                    {!couponLoading && !couponSuccess && <span>Apply Coupon &nbsp;<TagOutlined /></span>}
                    {couponSuccess && <span>Coupon Applied &nbsp;<CheckOutlined /></span>}
                    {couponLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />}
                </Button>
            </div>
            {billing.couponError && <span className="modal-error">Coupon is not found or has expired</span>}
            <span style={{ color: 'grey', fontSize: '13px', display: 'block', cursor: 'pointer' }} onClick={toggleCouponField}>
                I don't have a coupon code
            </span>
        </div>
    )
}

export const BillingDetails = props => {
    const { billing, handleSetBillingDetailsProp, validationErrors } = props;
    const { billingDetails } = billing;

    return (
        <div style={{ margin: '0 0 10px 0' }}>
            <BillingDetailsForm billingDetails={billingDetails} isCheckout setProp={handleSetBillingDetailsProp} errors={validationErrors} />
        </div>
    )
}

export const FinePrint = ({ toggleCouponField, renewalDate, settings }) => {
    return (
        <Row style={{ lineHeight: '18px', marginTop: '10px' }}>
            <ul style={{ paddingLeft: 20, color: '#7b7b7b' }}>
                {renewalDate && <li>Your plan will be renewed on <b>{renewalDate}</b>.</li>}
                {!settings.hasActiveSubscription && <li>If you decide AMZ Watcher isn't right for you, contact us within 7 days of being billed and we'll give you a refund.</li>}
                {!settings.hasActiveSubscription && <li>If you have a coupon code, apply it <span style={{ color: 'grey', textDecoration: 'underline', cursor: 'pointer' }} onClick={toggleCouponField}>here</span>.</li>}
                <li>You can cancel or modify your subscription at any time in your Settings. When you cancel, your plan is kept active until the next billing date.</li>
                <li>By placing this order, you agree to our <a href="https://amzwatcher.com/tos/" target="_blank" style={{ color: 'grey', textDecoration: 'underline' }}>Terms of Service</a>.</li>
            </ul>
        </Row>
    )
}
