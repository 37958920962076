import { handleActions } from 'redux-actions';
import fetch from '../services/fetch';
import logger from '../services/errorlogger';
import history from '../history';

import { actions as ordersActions } from './OrdersReducer';

export const actionTypes = {
    SMS_SEND_SUCCESS: 'SMS_SEND_SUCCESS',
    SMS_SEND_ERROR: 'SMS_SEND_ERROR',
    SMS_SEND_LOADING: 'SMS_SEND_LOADING',
    FLUSH_SMS: 'FLUSH_SMS',
};

export const actions = {
    flushSMS: () => async (dispatch, getState) => dispatch({ type: actionTypes.FLUSH_SMS }),
    sendSMS: (config, orderId) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.SMS_SEND_LOADING });
        try {
            const result = await fetch.post(`sms`, { config });
            console.log('SMS sending result', result);
            if (!result.data || result.data.response_status !== 'SUCCESS_MESSAGE_ACCEPTED') {
                return dispatch({ type: actionTypes.SMS_SEND_ERROR });
            }
            dispatch(ordersActions.setOrderSmsStatus(orderId, true));
            dispatch({ type: actionTypes.SMS_SEND_SUCCESS });
        } catch (e) {
            dispatch({ type: actionTypes.SMS_SEND_ERROR });
            logger.handleError(e);
        }
    },
};

export const getDefaultState = () => ({
    SMSSendSuccess: false,
    SMSSendError: false,
    SMSSendLoading: false,
});

export default handleActions({
    [actionTypes.FLUSH_SMS]: () => ({ ...getDefaultState() }),
    [actionTypes.SMS_SEND_LOADING]: (state) => ({ ...state, SMSSendLoading: true, SMSSendError: false, SMSSendSuccess: false }),
    [actionTypes.SMS_SEND_ERROR]: (state) => ({ ...state, SMSSendLoading: false, SMSSendError: true, SMSSendSuccess: false }),
    [actionTypes.SMS_SEND_SUCCESS]: (state) => ({ ...state, SMSSendLoading: false, SMSSendError: false, SMSSendSuccess: true }),
}, getDefaultState());