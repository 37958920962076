import React, { Component } from 'react';
import { Input, Tooltip, Switch } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

class BillingInformationForm extends Component {
    state = {
        company: null,
        name: null,
        line1: null,
        line2: null,
        postal_code: null,
        city: null,
        country: null,
        notes: null,
        sendInvoices: null,
        // company: 'Google',
        // name: 'Name Surname',
        // line1: 'Test Line 1',
        // line2: 'Test Line 2',
        // postal_code: '90211',
        // city: 'Los Angeles',
        // country: 'US',
        // notes: 'VAT NUMBER',
    }

    componentDidMount() {
        this.setState({ ...this.props.billingDetails, sendInvoices: this.props.sendInvoices });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.billingDetails) !== JSON.stringify(this.props.billingDetails)) {
            // object spread creates new unequal objects which react sees differently
            this.setState({ ...this.props.billingDetails, sendInvoices: this.props.sendInvoices });
        }
    }

    setProp = (prop, value) => {
        this.setState({ [prop]: value });
        this.props.setProp(prop, value);
    }

    render() {
        const { isCheckout, email, errors } = this.props;
        const {
            fullNameError,
            zipError,
            countryError,
            cityError,
            addressError,
            notesError,
        } = errors;
        const {
            name,
            company,
            line1,
            line2,
            postal_code,
            city,
            country,
            notes,
            sendInvoices,
        } = this.state;

        return (
            <div>
                <div style={{ textAlign: 'left', fontWeight: '700', fontSize: '15px', marginTop: '10px' }}>Billing Details</div>
                <div style={{ margin: '10px 0 25px 0' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ paddingRight: '10px', width: '50%' }}>
                            <Input placeholder="Full Name" value={name} onChange={e => this.setProp('name', e.target.value)} className={fullNameError ? 'checkout-field-error' : ''} />
                            {fullNameError && <span className="modal-error modal-error-field">This field is required</span>}
                        </div>
                        <div style={{ width: '50%' }}>
                            <Input placeholder="Company Name" value={company} onChange={e => this.setProp('company', e.target.value)} />
                        </div>
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        <Input placeholder="Address Line 1" value={line1} onChange={e => this.setProp('line1', e.target.value)} className={addressError ? 'checkout-field-error' : ''} />
                        {addressError && <span className="modal-error modal-error-field">This field is required</span>}
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                        <Input placeholder="Address Line 2" value={line2} onChange={e => this.setProp('line2', e.target.value)} />
                    </div>
                    <div style={{ display: 'flex', paddingTop: '10px' }}>
                        <div style={{ width: '150px' }}>
                            <Input placeholder="Postal Code" value={postal_code} onChange={e => this.setProp('postal_code', e.target.value)} className={zipError ? 'checkout-field-error' : ''} />
                            {zipError && <span className="modal-error modal-error-field">This field is required</span>}
                        </div>
                        <div style={{ padding: '0 10px' }}>
                            <Input placeholder="City" value={city} onChange={e => this.setProp('city', e.target.value)} className={cityError ? 'checkout-field-error' : ''} />
                            {cityError && <span className="modal-error modal-error-field">This field is required</span>}
                        </div>
                        <div style={{ width: isCheckout ? '150px' : '300px' }}>
                            <Input placeholder="Country" value={country} onChange={e => this.setProp('country', e.target.value)} className={countryError ? 'checkout-field-error' : ''} />
                            {countryError && <span className="modal-error modal-error-field">This field is required</span>}
                        </div>
                    </div>
                    <div style={{ paddingTop: '5px', color: 'darkgray', fontSize: '12px' }}>
                        Please make sure the address matches the one known by your bank.
                        <Tooltip title="Making sure the address is the same reduces the chances of having a payment declined. The postal / ZIP code is particularly important.">
                            <QuestionCircleOutlined style={{ fontSize: '12px', padding: '0 0 0 5px' }} />
                        </Tooltip>
                    </div>
                    <div style={{ paddingTop: '15px' }}>
                        <div style={{ margin: '0 0 3px 3px' }}>Notes <span style={{ color: 'darkgray', fontSize: '12px' }}>(max 29 characters)</span></div>
                        <Input
                            style={{ fontSize: '13px' }}
                            value={notes}
                            maxLength={29}
                            onChange={e => this.setProp('notes', e.target.value)}
                            placeholder="This note will appear on your invoices (VAT number, etc.)"
                        />
                        {notesError && <span className="modal-error modal-error-field">Note should be less than 29 characters</span>}
                    </div>
                    {!isCheckout && (<div style={{ paddingTop: '15px' }}>
                        <Switch size="small" checked={sendInvoices} style={{ marginRight: 10 }} onChange={e => this.setProp('sendInvoices', e)} />
                        Send Invoices to {email}
                    </div>)}
                </div>
            </div>
        )
    }
}

export const validateBillingInformationForm = (address, setErrors) => {
    const {
        name,
        line1,
        postal_code,
        city,
        country,
        notes,
    } = address;

    const fullNameError = !name || name.length === 0;
    const zipError = !postal_code || postal_code.length === 0;
    const countryError = !country || country.length === 0;
    const addressError = !line1 || line1.length === 0;
    const cityError = !city || city.length === 0;
    const notesError = notes && notes.length > 29;

    setErrors({
        fullNameError,
        zipError,
        countryError,
        cityError,
        addressError,
        notesError,
    });

    const isFormValid = !(fullNameError || zipError || countryError || cityError || addressError || notesError);

    return isFormValid;
}

export default BillingInformationForm;