import React, { Component } from 'react';
import { Button, Modal, Radio, Input, Spin } from 'antd';
import history from '../../../history';

import { LoadingOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const reasons = [
    'I finished the work I needed to do',
    'I switched to another tool',
    'The data quality is too low',
    'A feature is missing',
    'AMZ Watcher is too expensive',
    'Other reason',
];

class CancelAccountModal extends Component {
    state = { selectedTerm: null, explanation: null }

    toggleCancelPlanModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    getWebsiteCount = websites => {
        if (websites === 999) return 'Unlimited';
        return websites;
    }

    render() {
        const {
            isVisible,
            onCloseModal,
            onCancelAccount,
            billing
        } = this.props;

        const {
            cancelAccountLoading,
            cancelAccountError,
        } = billing;

        return (
            <Modal
                title="Cancel my subscription"
                visible={isVisible}
                onCancel={onCloseModal}
                width={600}
                footer={[(
                    <Button
                        onClick={() => onCancelAccount(reasons[this.state.selectedTerm], this.state.explanation)}
                        disabled={cancelAccountLoading}
                        key="OK"
                    >
                        {cancelAccountLoading && <Spin indicator={<LoadingOutlined style={{ marginRight: '10px' }} spin />} />}
              Cancel subscription
                    </Button>),
                <Button onClick={() => history.push('/app/dashboard/')} disabled={cancelAccountLoading} key="NOP">Return to dashboard</Button>
                ]}
            >
                <div style={{ marginBottom: '10px' }}>
                    To temporarily stop your subscription, you can also pause it and get a discount. If there's anything we can do to help, please contact us.
            </div>

                <div style={{ padding: '10px 0' }}><b>Why would you like to cancel your subscription?</b></div>
                <Radio.Group
                    style={{ width: 200, padding: '0 0 10px 0' }}
                    onChange={e => this.setState({ selectedTerm: e.target.value })}
                    defaultValue={this.state.selectedTerm}
                >
                    {reasons.map((r, id) => (<Radio value={id} key={id}>{r}</Radio>))}
                </Radio.Group>
                <TextArea
                    rows={4}
                    value={this.state.explanation}
                    onChange={e => this.setState({ explanation: e.target.value })}
                />
                <div style={{ margin: '10px 0' }}>This is the right place for feedback. For any inquiry, please contact us directly.</div>
                {cancelAccountError && <div className="ant-form-extra" style={{ marginTop: '10px', fontSize: '12px' }}>There has been an error cancelling your account. Please reach out to support of the error persists.</div>}
            </Modal>
        )
    }
}

export default CancelAccountModal;