import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    List,
    Button,
    Switch,
    Modal,
    DatePicker,
    Input,
    message,
    Divider,
} from 'antd';
import { SyncOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { TextArea } = Input;

class AgentModal extends Component {
    state = { 

    }

    // cleanPhone = () => {

    // }

    componentDidMount() {

    }


    render() {
        const { isVisible, onClose, order } = this.props;
        if (!order) return null;
        
        const { sum, description } = order;
        const agent = order.agent.full;

        const { phone, legalAddress, name, actualAddress, email } = agent;

        return (
            <div style={{ textAlign: 'left' }} className="admin-panel-tab">
                <Modal
                    title="Данные по заказу"
                    visible={isVisible}
                    footer={null}
                    onCancel={onClose}
                    width={700}
                >
                    <div style={{ margin: '10px 0' }}>
                    </div>
                    <div style={{ marginBottom: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>Имя</div>
                            <Input style={{ margin: '0 10px' }} value={name} contentEditable={false} /> 
                            <CopyToClipboard text={name} onCopy={() => message.success('Скопировано!')}><Button>Копировать</Button></CopyToClipboard>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>Телефон</div>
                            <Input style={{ margin: '0 10px' }} value={phone} contentEditable={false} />
                            <CopyToClipboard text={phone} onCopy={() => message.success('Скопировано!')}><Button>Копировать</Button></CopyToClipboard>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>E Mail</div>
                            <Input style={{ margin: '0 10px' }} value={email} contentEditable={false} />
                            <CopyToClipboard text={email} onCopy={() => message.success('Скопировано!')}><Button>Копировать</Button></CopyToClipboard>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>Адрес</div>
                            <TextArea style={{ margin: '0 10px' }} value={legalAddress} contentEditable={false} rows={2} />
                            <CopyToClipboard text={legalAddress} onCopy={() => message.success('Скопировано!')}><Button>Копировать</Button></CopyToClipboard>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>Адрес 2</div>
                            <TextArea style={{ margin: '0 10px' }} value={actualAddress} contentEditable={false} rows={2} />
                            <CopyToClipboard text={actualAddress} onCopy={() => message.success('Скопировано!')}><Button>Копировать</Button></CopyToClipboard>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>Сумма заказа</div>
                            <Input style={{ margin: '0 10px' }} value={sum / 100} contentEditable={false} />
                            <CopyToClipboard text={sum / 100} onCopy={() => message.success('Скопировано!')}><Button>Копировать</Button></CopyToClipboard>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'baseline', margin: '10px 0' }}>
                            <div style={{ width: 125 }}>Описание заказа</div>
                            <TextArea style={{ margin: '0 10px' }} value={description} rows={15} contentEditable={false} />
                            <Button disabled>Копировать</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default AgentModal;