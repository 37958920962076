import React, { Component } from 'react';
import { Button, Modal, Card } from 'antd';
import logger from '../../../services/errorlogger';

import { CreditCardInputForm, ContunueButton, validateCreditCardInputForm } from '../../../components/CheckoutModal/StripeCheckout';
import { injectStripe, StripeProvider, Elements } from 'react-stripe-elements';

class AddCardModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formError: null,
            validationErrors: {},
        };
        this.formRef = React.createRef();
    }

    handleSubmit = async e => {
        try {
            const { stripe, addCard, onCloseModal } = this.props;

            e.preventDefault();

            const isValid = this.isFormValid();
            if (!isValid) return false;

            if (!stripe) throw new Error('Payment Provider failed to load. Please disable adblocker or reach out to support');

            this.setState({ loading: true });
            const node = this.formRef.current;
            const name = (node[0] && node[0].value) || null;
            const payload = await stripe.createToken();
            console.log('[token]', payload);
            if (payload && payload.error) {
                return this.setState({ formError: payload.error.message, loading: false });
            }
            this.setState({ formError: null, loading: false });
            addCard(payload.token, { name });
        } catch(e) {
            console.log(JSON.stringify({ message: e.message, stack: e.stack }));
            logger.logError(e);
            this.setState({ loading: false });
        }    
    };

    isFormValid = () => {
        const currentErrors = this.state.validationErrors;
        const ccvalid = validateCreditCardInputForm(this.formRef, errs => this.setState({ validationErrors: { ...currentErrors, ...errs } }));
        return ccvalid;
    }

    render() {
        const {
            isVisible,
            onCloseModal,
            billing,
        } = this.props;

        const { cardAddSuccess, cardsError, cardsLoading } = billing;

        const {
            loading,
            validationErrors,
        } = this.state;

        const formError = this.state.formError || cardsError;

        return isVisible && (
            <Modal
                title="Add Credit Card"
                visible={isVisible}
                onCancel={onCloseModal}
                width={500}
                footer={null}
                loading={cardsLoading}
            >
                <div style={{ marginBottom: '10px' }}>
                    Insert your credit card details down below.
                </div>
                <Card className="checkout">
                    {cardAddSuccess && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ marginBottom: '20px' }}>Card Added!</span>
                            <Button type="primary" onClick={onCloseModal}>Close</Button>
                        </div>
                    )}
                    {!cardAddSuccess && (
                        <div>
                            <CreditCardInputForm {...this.props} formRef={this.formRef} validationErrors={validationErrors} />
                            <div style={{ marginTop: 20 }}>
                                <ContunueButton {...this.props} isPaymentLoading={loading} handleSubmit={this.handleSubmit} buttonText="Add Credit Card" />
                            </div>
                            {formError && <span className="modal-error">There has been a problem adding your card. Please try again or contact support</span>}
                        </div>
                    )}
                </Card>
            </Modal>
        )
    }
}

function provideStripeWrapper(WrappedComponent) {
    return props => (
        <StripeProvider apiKey={props.billing.key}>
            <Elements>
                <WrappedComponent {...props} />
            </Elements>
        </StripeProvider>)
};

export default provideStripeWrapper(injectStripe(AddCardModal));