import React, { Component } from 'react';
import { Button, List, Alert, Spin, Card } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getStripePlanKeys } from '../../../store/environment';
import history from '../../../history';

import { LoadingOutlined } from '@ant-design/icons';

import PauseAccountModal from './PauseAccountModal';
import CancelAccountModal from './CancelAccountModal';

class PlanDetails extends Component {
    state = {
        showPausePlanModal: false,
        showCancelPlanModal: false,
    }

    toggleCancelPlanModal = () => {
        this.setState({ showCancelPlanModal: !this.state.showCancelPlanModal });
    }

    togglePausePlanModal = () => {
        this.setState({ showPausePlanModal: !this.state.showPausePlanModal });
    }

    onCancelAccount = (reason, explanation) => {
        this.props.cancelAccount({ reason, explanation });
    }

    onAbortCancelAccount = () => {
        this.props.abortCancelAccount();
    }

    onPauseAccount = term => {
        this.props.pauseAccount(term, getStripePlanKeys(false)['hibernate']);
    }

    getWebsiteCount = websites => {
        if (websites === 999) return 'Unlimited';
        return websites;
    }

    onShowCancelAccountModal = () => {
        this.setState({ showPausePlanModal: false }, () => this.setState({ showCancelPlanModal: true }));
    }

    render() {
        const {
            created,
            planCredits,
            periodEnd,
            isYearly,
            permissions,
            plan,

            isCancelled,
            willCancel,
            isPaused,
            willPause,
        } = this.props.settings;

        const {
            abortCancelAccountLoading,
        } = this.props.billing;
        // debugger;
        const data = [
            {
                key: 'Plan',
                render: () => <span style={{ textTransform: 'capitalize' }}>{plan || 'None'}</span>,
            },
            {
                key: 'Subscription Term',
                render: () => isYearly ? 'Yearly' : 'Monthly',
            },
            {
                key: 'Subscription Status',
                render: () => {
                    let value = 'Active';
                    if (isCancelled) value = 'Cancelled';
                    if (willCancel) value = 'Will Cancel End Of Billing Cycle';
                    if (isPaused) value = 'Paused';
                    if (willPause) value = 'Will Pause End Of Billing Cycle';
                    return <span style={{ textTransform: 'capitalize' }}>{value}</span>
                }
            },
            !isCancelled && {
                key: (willCancel && !isPaused) ? 'Subscription Ends On' : 'Payment Coming Up',
                render: () => `${moment.unix(periodEnd).format('DD MMMM YYYY')}`,
            },
            {
                key: 'Subscription Paid Until',
                render: () => `${moment.unix(periodEnd).format('DD MMMM YYYY')}`,
            },
            {
                key: 'Pages',
                render: () => `${planCredits} / month`,
            },
            {
                key: 'Websites',
                render: () => `${this.getWebsiteCount(permissions.allowedProjects) || 0}`,
            },
        ].filter(f => !!f);

        const CancelPlanButton = () => {
            if (isCancelled || willCancel) return null;
            return (
                <Button type="secondary" key="action2" style={{ marginLeft: '10px' }} onClick={this.togglePausePlanModal}>
                    Cancel Plan
                </Button>
            );
        }

        const DontCancelPlanButton = () => {
            if (!willCancel || isPaused || willPause) return null;
            return (
                <Button
                    type="secondary"
                    key="action3"
                    style={{ marginLeft: '10px' }}
                    disabled={abortCancelAccountLoading}
                    onClick={this.onAbortCancelAccount}
                >
                    {abortCancelAccountLoading && <Spin indicator={<LoadingOutlined style={{ marginRight: '10px' }} spin />} />}
                    Don't Cancel
                </Button>
            )
        };

        const actions = [
            <Link to="/app/pricing/" key="action1">
                <Button type="primary">
                    Change Plan
                </Button>
            </Link>,
            <CancelPlanButton />,
            <DontCancelPlanButton />,
        ];

        return (
            <div style={{ textAlign: 'left', }}>
                <Card title="My Subscription" actions={actions} style={{ width: 600 }}>
                    {!isYearly && !isCancelled && <Alert message="Switch to yearly and get 2 months free" type="success" style={{ cursor: 'pointer' }} onClick={() => history.push('/app/pricing/')} />}
                    <List
                        dataSource={data}
                        renderItem={(item, i) => (
                            <List.Item key={`item.value${i}`} style={{ display: 'flex' }}>
                                <span style={{ width: '200px' }}>{item.key}:</span>{item.render()}
                            </List.Item>
                        )}
                    />
                </Card>


                <PauseAccountModal
                    isVisible={this.state.showPausePlanModal}
                    onCloseModal={this.togglePausePlanModal}
                    onShowCancelAccountModal={this.onShowCancelAccountModal}
                    onPauseAccount={this.onPauseAccount}
                    {...this.props}
                />
                <CancelAccountModal
                    isVisible={this.state.showCancelPlanModal}
                    onCloseModal={this.toggleCancelPlanModal}
                    onCancelAccount={this.onCancelAccount}
                    {...this.props}
                />
            </div>
        )
    }
}

export default PlanDetails;