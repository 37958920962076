import React, { Component, Fragment } from 'react';
import { Button, Input, Typography, Breadcrumb, message, Tag, List } from 'antd';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import history from '../../history';

import { actions as ProductDetailsActions } from '../../store/ProductDetailsReducer';
import ProductsTable from './ProductsTable';

// import OrdersTable from './OrdersTable';

const { Title } = Typography;

class OrderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: '', 
            category: '', 
            sku: '',
             url: '',
        };
    }

    componentDidMount() {
        document.title = 'Product Details | Liliya';
        // this.props.fetchOrders({});
    }

    reset = () => {
        console.log('flishing')
        this.props.flushProductDetails()
        this.setState({ name: '', category: '', sku: '', url: '' })
    }

    onSearch = () => {
        const { name, category, sku, url } = this.state
        this.props.fetchProductDetails({ name, category, sku, url })
    }

    render() {
        const { name, category, url, sku } = this.state;
        const { products, productDetailsLoading, productDetailsError } = this.props.productDetails;
        console.log(products)
        return (
            <div>
                <div>
                    <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>Характеристики</Title>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: 20 }}>
                    <div>
                        <div style={{ marginBottom: 10 }}>Пошук</div>
                        <div style={{ gap: 5, display: 'flex', flexDirection: 'column'}}>
                            <div>
                                <div style={{ width: 100, display: 'inline-block'}}>Назва</div>
                                <Input disabled={productDetailsLoading} value={name} style={{ width: 300 }} onChange={e => this.setState({ name: e.target.value })} /></div>
                            <div>
                                <div style={{ width: 100, display: 'inline-block'}}>URL</div>
                                <Input disabled={productDetailsLoading} value={url} style={{ width: 300 }} onChange={e => this.setState({ url: e.target.value })} /></div>
                            <div>
                                <div style={{ width: 100, display: 'inline-block'}}>Категорія</div>
                                <Input disabled={productDetailsLoading} value={category} style={{ width: 300 }} onChange={e => this.setState({ category: e.target.value })} /></div>
                            <div>
                                <div style={{ width: 100, display: 'inline-block'}}>SKU</div>
                                <Input disabled={productDetailsLoading} value={sku} style={{ width: 300 }} onChange={e => this.setState({ sku: e.target.value })} /></div>
                        </div>
                       
                        <Button 
                            disabled={productDetailsLoading} 
                            loading={productDetailsLoading} 
                            style={{ marginTop: 20 }} type="primary" onClick={this.onSearch}
                        >
                            Пошук
                        </Button>
                        {products.length ? <Button loading={productDetailsLoading} style={{ marginTop: 20, marginLeft: 10 }} onClick={this.reset}>Скинути</Button> : null}
                    </div>
                    {productDetailsError && <div>there has been an error while fetching the product details</div>}
                </div>
                Всього: {products.length}
                <ProductsTable data={products} isLoading={productDetailsLoading} />

                {/* <OrdersTable data={orders} showAgentModal={this.toggleAgentModal} showSMSModal={this.toggleSMSModal} isLoading={loading} /> */}
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...ProductDetailsActions,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrderContainer);