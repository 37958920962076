import { handleActions } from 'redux-actions';
import fetch from '../services/fetch';
import logger from '../services/errorlogger';
import URI from 'urijs';
import { message } from 'antd';

export const actionTypes = {
    FETCH_PRODUCT_DETAILS_LOADING: 'FETCH_PRODUCT_DETAILS_LOADING',
    FETCH_PRODUCT_DETAILS_ERROR: 'FETCH_PRODUCT_DETAILS_ERROR',
    FETCH_PRODUCT_DETAILS_SUCCESS: 'FETCH_PRODUCT_DETAILS_SUCCESS',

    FLUSH_PRODUCT_DETAILS: 'FLUSH_PRODUCT_DETAILS',
};

export const actions = {
    flushProductDetails: () => async (dispatch, getState) => dispatch({ type: actionTypes.FLUSH_PRODUCT_DETAILS }),
    fetchProductDetails: ({ name, url, sku, category }) => async (dispatch, getState) => {
        dispatch({ type: actionTypes.FETCH_PRODUCT_DETAILS_LOADING });
        try {
            let search = {}
            if (name) {
                search.name = name
            }
            if (url) {
                search.url = url
            }
            if (sku) {
                search.url = sku
            }
            if (category) {
                search.category = category
            }
            if (Object.keys(search).length === 0) {
                message.info('Add a filter')
                dispatch({ type: actionTypes.FLUSH_PRODUCT_DETAILS });
                return 
            }
            const fetchUrl = URI('products').search(search)
            const { data: products } = await fetch.get(`${fetchUrl}`);
            dispatch({ type: actionTypes.FETCH_PRODUCT_DETAILS_SUCCESS, products });
        } catch (e) {
            dispatch({ type: actionTypes.FETCH_PRODUCT_DETAILS_ERROR });
            logger.handleError(e);
        }
    },
};

export const getDefaultState = () => ({
    productDetailsLoading: false,
    productDetailsSuccess: false,

    products: [],
});

export default handleActions({
    [actionTypes.FLUSH_PRODUCT_DETAILS]: () => ({ ...getDefaultState() }),
    [actionTypes.FETCH_PRODUCT_DETAILS_LOADING]: state => ({ ...state, productDetailsLoading: true, productDetailsError: false }),
    [actionTypes.FETCH_PRODUCT_DETAILS_SUCCESS]: (state, { products }) => {
        return ({ ...state, products, productDetailsLoading: false, productDetailsError: false, });
    },
    [actionTypes.FETCH_PRODUCT_DETAILS_ERROR]: state => ({ ...state, productDetailsLoading: false, productDetailsError: true }),
}, getDefaultState());