import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as SettingsReducer } from '../../store/SettingsReducer';

import { Card } from 'antd';

class VerifyEmail extends Component {
    componentWillMount() {
        document.title = 'Account Inactive | Zakluchator App';
    }

    signOut = () => {
        this.props.signOutUser();
    }

    render() {
        return (
            <div className="signup-form">
                <Card
                    title={`Account Inactive`}
                    className="signup-card"
                    headStyle={{ fontSize: 20 }}
                    style={{ width: '500px' }}
                >
                    <div style={{ textAlign: 'left' }}>
                        {/* <Title level={4} >Check Your Email!</Title> */}
                        <span style={{ margin: '10px 0 20px 0', display: 'block' }}>
                            This account is not active. Please refesh this page or contact support.
                        </span>
                    </div>
                </Card>
                <div>
                    Not Your Email?&nbsp;
                    <span
                        style={{ cursor: 'pointer', color: '#1890ff' }}
                        onClick={this.signOut}
                    >
                        Logout
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifyEmail);