import React, { Component, Fragment } from 'react';
import { Button, List, Tag, Card, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import ChangeBillingDetailsModal from './ChangeBillingDetailsModal';
import AddCardModal from './AddCardModal';

const { Item } = List;

class BillingDetails extends Component {
    state = {
        showAddCardModal: false,
        showChangeBillingDetailsModal: false,
    }

    componentDidMount() {
        this.props.fetchBillingDetails();
        this.props.fetchCards();
    }

    toggleChangeBillingDetailsModal = () => {
        this.setState({ showChangeBillingDetailsModal: !this.state.showChangeBillingDetailsModal });
    }

    toggleAddCardModal = () => {
        this.setState({ showAddCardModal: !this.state.showAddCardModal });
        this.props.resetAddCard();
    }

    deleteCard = cardId => {
        this.props.deleteCard(cardId);
    }

    makePrimary = cardId => {
        this.props.makeCardPrimary(cardId);
    }

    renderBillingDetails = () => {
        const { billingDetails = {}, billingDetailsLoading, sendInvoices } = this.props.billing;

        // if (billingDetailsLoading) return <Spin />;

        const { name, address = {}, notes } = billingDetails;
        const { company, line1, line2, postal_code, city, country } = address;

        return (
            <Card title="Billing Information" style={{ width: '100%' }}>
                <List
                    size="small"
                    className="billing-list"
                    loading={billingDetailsLoading}
                    footer={<Button type="primary" onClick={this.toggleChangeBillingDetailsModal} key="action">Edit My Information</Button>}
                >
                    {name && <Item><span className="billing-list-item-title">Name:</span> {name}</Item>}
                    {company && <Item><span className="billing-list-item-title">Company:</span> {company}</Item>}
                    {line1 && <Item><span className="billing-list-item-title">Address line 1:</span> {line1}</Item>}
                    {line2 && <Item><span className="billing-list-item-title">Address line 2:</span> {line2}</Item>}
                    {postal_code && <Item><span className="billing-list-item-title">Postal Code:</span> {postal_code}</Item>}
                    {city && <Item><span className="billing-list-item-title">City:</span> {city}</Item>}
                    {country && <Item><span className="billing-list-item-title">Country:</span> {country}</Item>}
                    {notes && <Item><span className="billing-list-item-title">Notes:</span> {notes}</Item>}
                    <Item><span className="billing-list-item-title">Send Invoices:</span> {sendInvoices ? 'Yes' : 'No'}</Item>
                </List>
            </Card>
        );
    }

    renderCard = () => {
        const { cards, cardsLoading } = this.props.billing;
        const { defaultCardId } = this.props.settings;

        return (
            <Card title="Payment Cards" style={{ width: '100%' }}>
                <List
                    loading={cardsLoading}
                    header={<strong>Saved Cards</strong>}
                    footer={<Button type="primary" onClick={this.toggleAddCardModal} key="action">Add Card</Button>}
                    dataSource={cards}
                    className="billing-list"
                    size="small"
                    // headStyle={{ paddingTop: 0 }}
                    renderItem={({ brand, last4, name, id }) => (
                        <Item
                            key={id}
                            actions={defaultCardId !== id ? [
                                <Button size="small" style={{ marginRight: '10px' }} onClick={() => this.makePrimary(id)} key="primary">Make Primary</Button>,
                                <Button size="small" onClick={() => this.deleteCard(id)} icon={<DeleteOutlined />} key="remove"></Button>,
                            ] : [<Tag key="primary" color="green">Primary Card</Tag>]}
                        >
                            {`${name || ''} ${brand} *${last4}`}
                        </Item>
                    )}
                />
            </Card>
        )
    }

    render() {
        const { showChangeBillingDetailsModal, showAddCardModal } = this.state;
        return (
            <div style={{ textAlign: 'left', marginTop: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '49%' }}>
                        {this.renderCard()}
                    </div>
                    <div style={{ width: '49%', }}>
                        {this.renderBillingDetails()}
                    </div>
                </div>
                <AddCardModal {...this.props} isVisible={showAddCardModal} onCloseModal={this.toggleAddCardModal} />
                <ChangeBillingDetailsModal {...this.props} isVisible={showChangeBillingDetailsModal} onCloseModal={this.toggleChangeBillingDetailsModal} />
            </div>
        )
    }
}

export default BillingDetails;