import React, { Component } from 'react';
import { Form, Input, Button, Card } from 'antd';

class EditEmailForm extends Component {
    handleSubmit = ({ email, password, newEmail }) => {
        const { updateUserEmail } = this.props;
        updateUserEmail({ email, password, newEmail });
    };

    confirmEmailValidator = ({ getFieldValue }) => ({
        validator(rule, value) {
            if (value && value === getFieldValue('email')) {
                return Promise.reject('Please provide another email');
            }
            return Promise.resolve();
        }
    })

    render() {
        const { name, email, provider, updateLoading } = this.props.settings;

        const formItemLayout = {
            labelCol: {
                xs: { span: 7 },
                sm: { span: 7 },
            },
            wrapperCol: {
                xs: { span: 10 },
                sm: { span: 10 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: { span: 10, offset: 7 },
                sm: { span: 10, offset: 7 },
            },
        };

        const isSSO = provider !== 'password';

        return (
            <div style={{ textAlign: 'left', marginTop: '20px', width: 600 }}>
                <Card title={<span>Email Address</span>}>
                    {isSSO && <div>Changing your email is not allowed for Signle-Sign-On accounts. (Google, Twitter, Facebook, etc.)</div>}
                    {!isSSO && (<Form {...formItemLayout} layout="horizontal" onFinish={this.handleSubmit} initialValues={{ name, email }}>
                        <Form.Item label="Current Email" name="email">
                            <Input
                                className="account-form-input"
                                placeholder="your@email.com"
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            label="New Email"
                            name="newEmail"
                            rules={[{ required: true, message: 'Please provide your new email' }, this.confirmEmailValidator]}
                        >
                            <Input
                                className="account-form-input"
                                placeholder="your@email.com"
                                type="email"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            rules={[{ required: true, message: 'Please confirm your password' }]}
                            name="password"
                        >
                            <Input
                                className="account-form-input"
                                placeholder="Confirm Your Password"
                                type="password"
                            />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" loading={updateLoading} disabled={updateLoading}>
                                Save
                            </Button>
                        </Form.Item>
                    </Form>)}
                </Card>
            </div>
        );
    }
}

export default EditEmailForm;