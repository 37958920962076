// eslint-disable-next-line
const crashReporter = store => next => action => {
    try {
        return next(action);
    } catch (err) {
        // eslint-disable-next-line
        console.warn(`Failed to apply action ${action.type}`, {
            err,
            action,
        });
        throw err;
    }
};

export default crashReporter;
