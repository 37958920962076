import React, { Component } from 'react';
import { Button, List, Tag, Card, message } from 'antd';
import moment from 'moment';

class BillingHistory extends Component {
    componentDidMount() {
        this.props.fetchInvoices();
    }

    openInvoice = url => {
        window.open(url);
    }

    payInvoice = id => {
        if (this.props.billing.cards.length === 0) {
            return message.info('Please Add A Payment Card');
        }
        this.props.payInvoice(id);
    }

    render() {
        const { invoicesLoading, invoices, invoicePaymentLoading } = this.props.billing;

        const allowedInvoices = invoices.filter(i => i.status !== 'draft' || i.amount > 0);
        return (
            <Card style={{ textAlign: 'left' }} title="Billing History">
                <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Please allow up to 24 hours for your new invoices to become available.</div>
                <div style={{ width: '800px' }}>
                    <List
                        header={<strong>Invoices</strong>}
                        itemLayout="horizontal"
                        dataSource={allowedInvoices}
                        loading={invoicesLoading}
                        size="small"
                        className="billing-list"
                        renderItem={invoice => {
                            const canDownloadInvoice = invoice.status === 'paid';
                            const canPayInvoice = invoice.status !== 'paid' && invoice.status !== 'void' && invoice.status !== 'draft';
                            const actions = [
                                canDownloadInvoice && <Button type="link" size="small" onClick={() => this.openInvoice(invoice.url)}>Download</Button>,
                                canPayInvoice && <Button type="primary" size="small" onClick={() => this.payInvoice(invoice.id)} style={{ marginLeft: '10px' }} loading={invoicePaymentLoading}>
                                    Pay Now
                                </Button>,
                            ].filter(s => !!s);

                            return (<List.Item key={invoice.url} actions={actions}>
                                <List.Item.Meta
                                    title={<span>Date: {moment.unix(invoice.created).format('DD MMMM YYYY')}</span>}
                                    description={`$ ${invoice.amount / 100}`}
                                />
                                <div><Tag color={invoice.status === 'paid' && 'green'} style={{ textTransform: 'capitalize' }}>{invoice.status}</Tag></div>
                            </List.Item>)
                        }}
                    />
                </div>
            </Card>
        )
    }
}

export default BillingHistory;