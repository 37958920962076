import React, { Component, Fragment } from 'react';
import { Button, Input, Typography, Breadcrumb, message, Tag, List } from 'antd';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import history from '../../history';

import { actions as OrdersActions } from '../../store/OrdersReducer';
import { actions as SMSActions } from '../../store/SMSReducer';

import OrdersTable from './OrdersTable';
import MessageModal from './MessageModal';
import AgentModal from './AgentModal';

const { Title } = Typography;

class OrderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showMessageModal: false,
            selectedOrderId: null,
            showAgentModal: false,
        };
    }

    componentDidMount() {
        document.title = 'Замовлення | Liliya';
        this.props.fetchOrders({});
    }

    toggleSMSModal = (selectedOrderId) => {
        this.setState({ showMessageModal: !this.state.showMessageModal, selectedOrderId });
    }

    toggleAgentModal = (selectedOrderId) => {
        this.setState({ showAgentModal: !this.state.showAgentModal, selectedOrderId });
    }

    render() {
        const { showMessageModal, showAgentModal, selectedOrderId } = this.state;
        const { sendSMS } = this.props;
        const { orders, loading } = this.props.orders;
        const selectedOrder = orders.find(o => o.id === selectedOrderId);
        return (
            <div>
                <div>
                    <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>Заказы</Title>
                </div>
                {/* <Button onClick={() => history.push('/app/orders/create')}>Create Order</Button> */}
                <OrdersTable data={orders} showAgentModal={this.toggleAgentModal} showSMSModal={this.toggleSMSModal} isLoading={loading} />
                {showMessageModal && <MessageModal isVisible={showMessageModal} onSendSMS={sendSMS} order={selectedOrder} onClose={this.toggleSMSModal} />}
                {showAgentModal && <AgentModal isVisible={showAgentModal} order={selectedOrder} onClose={this.toggleAgentModal} />}
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...OrdersActions,
    ...SMSActions,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OrderContainer);