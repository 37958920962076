const isProduction = process.env.NODE_ENV === 'production';

const tracker = {
    initiateCheckout: () => {
        if (!isProduction) return null;
        if (window.fbq) {
            console.log('track initiate checkout');
            window.fbq('track', 'InitiateCheckout');
        }
    },
    purchase: (price) => {
        if (!isProduction) return null;
        if (!price) return null;
        const value = +(price / 100).toFixed(2);
        if (window.fbq) {
            console.log('tracking purchase', value);
            window.fbq('track', 'Purchase', { value, currency: 'USD' });
        }
    },
    completeRegistration: () => {
        if (!isProduction) return null;
        if (window.fbq) {
            console.log('tracing complete Registration');
            window.fbq('track', 'CompleteRegistration');
        }
    }
};


export default tracker;