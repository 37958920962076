import React, { Component } from 'react';
import { Button, Progress, Card, Tooltip } from 'antd';
import history from '../../../history';

import BillingDetails from './BillingDetails';
import BillingHistory from './BillingHistory';
import PlanDetails from './PlanDetails';

class Billing extends Component {
    render() {
        return 'Billing';
        return (
            <div>
                <BillingDetails {...this.props} />
                <BillingHistory {...this.props} />
                <PlanDetails {...this.props} />
            </div>
        );
    }
}

export default Billing;
