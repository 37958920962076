import React, { Component } from 'react';
import { Button, Progress, Card, Tooltip } from 'antd';
import history from '../../../history';

class Integrations extends Component {
    render() {
        return (
            <div style={{ textAlign: 'left' }}>
                <Card title={<span>Integrations</span>} style={{ width: 500 }}>
                    Integrations
                    <div onClick={() => history.push('/app/orders-delta')}>Orders delta</div>
                </Card>
            </div>
        );
    }
}

export default Integrations;
