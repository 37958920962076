import React, { Component, Fragment } from 'react';
import { Button, Input, Typography, Breadcrumb, message, Tag, List, Spin } from 'antd';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import history from '../../history';

import { actions as OrdersActions } from '../../store/OrdersReducer';
import { actions as SMSActions } from '../../store/SMSReducer';

import OrdersTable from './OrdersTable';
import MessageModal from './MessageModal';
import AgentModal from './AgentModal';

const { Title } = Typography;

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showMessageModal: false,
            showAgentModal: false,
            searchString: '',
            selectedOrderId: null,
        };
    }

    componentDidMount() {
        document.title = 'Поиск Заказа | Liliya';
        this.props.flushOrders();
        this.props.flushSMS();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searchString && prevProps.ordersLoading !== this.props.ordersLoading && this.props.orders.length) {
            // history.push(`app/orders/search/list`);
        }
        if (this.state.searchString && prevProps.orderUpdateLoading !== this.props.orderUpdateLoading && this.props.orderUpdateSuccess) {
            debugger;
            this.onSearch();
        }
    }

    toggleSMSModal = (selectedOrderId) => {
        this.setState({ showMessageModal: !this.state.showMessageModal, selectedOrderId });
    }

    onCloseModal = () => {
        this.setState({ showMessageModal: false });
        this.onSearch();
        this.props.flushSMS();
    }

    toggleAgentModal = (selectedOrderId) => {
        this.setState({ showAgentModal: !this.state.showAgentModal, selectedOrderId });
    }

    onSearch = () => {
        this.props.fetchOrders({ search: this.state.searchString });
    }

    reset = () => {
        this.props.flushOrders();
        this.props.flushSMS();
    }

    render() {
        const { showMessageModal, showAgentModal, searchString, selectedOrderId } = this.state;
        const { sendSMS, sms } = this.props;
        const { orders = [], ordersLoading } = this.props.orders;
        const selectedOrder = orders.find(o => o.id === selectedOrderId);

        return (
            <div>
                <div>
                    <Title level={3} style={{ marginBottom: 5, marginTop: 0 }}>Поиск Заказа</Title>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: 20 }}>
                    <div>
                        <div style={{ marginBottom: 10 }}>Введите Номер Заказа</div>
                        <div><Input disabled={ordersLoading} value={searchString} style={{ width: 300 }} size="large" onChange={e => this.setState({ searchString: e.target.value })} /></div>
                        <Button disabled={!searchString || searchString.length < 5} loading={ordersLoading} style={{ marginTop: 20 }} size="large" type="primary" onClick={this.onSearch}>Поиск</Button>
                        {orders.length ? <Button loading={ordersLoading} style={{ marginTop: 20, marginLeft: 10 }} size="large" onClick={this.reset}>Сброс</Button> : null}
                    </div>
                </div>
                {orders.length ? <OrdersTable data={orders} showAgentModal={this.toggleAgentModal} showSMSModal={this.toggleSMSModal} isLoading={ordersLoading} /> : ''}
                {showMessageModal && <MessageModal isVisible={showMessageModal} onSendSMS={sendSMS} order={selectedOrder} onClose={this.toggleSMSModal} onCloseSent={this.onCloseModal} {...sms} />}
                {showAgentModal && <AgentModal isVisible={showAgentModal} order={selectedOrder} onClose={this.toggleAgentModal} />}
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...OrdersActions,
    ...SMSActions,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Search);