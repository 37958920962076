import React, { Component as C } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { actions as SettingsReducer } from '../store/SettingsReducer';

class AuthenticationMonitor extends C {
    componentDidMount() {
        this.props.monitorLogin();
    }

    isLoading = () => {
        const { loading } = this.props.settings;
        return loading;
    }

    render() {
        if (this.isLoading()) {
            return (
                <span style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
                    <Spin type="large" indicator={<LoadingOutlined style={{ fontSize: 40, marginBottom: 20 }} spin />} tip={<span style={{ fontSize: 15, fontWeight: 600 }}>Loading</span>} />
                </span>
            );
        }
        return this.props.children;
    }
};

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthenticationMonitor);