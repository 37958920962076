import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Card, Spin, Progress } from 'antd';
import { CheckCircleOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';

import { actions as SettingsReducer } from '../../store/SettingsReducer';
import history from '../../history';

class VerifyEmail extends Component {
    componentWillMount() {
        document.title = 'Verify Email | Zakluchator App';
        const { userId, emailVerified } = this.props.settings;
        if (userId && emailVerified) return history.push('/app/dashboard/');

        if (this.props.match.params.emailVerifyId) return this.verifyEmail();

        if (!userId) return history.push('/login');
    }

    componentDidUpdate() {
        const { userId, emailVerified } = this.props.settings;
        if (userId && emailVerified) return history.push('/app/dashboard/');

        if (this.props.match.params.emailVerifyId) return this.verifyEmail();

        if (!userId) return history.push('/login');
    }

    verifyEmail = () => {
        const {
            confirmationDone,
            confirmationFailed,
        } = this.props.settings;
        if (confirmationDone || confirmationFailed) return null;
        const { emailVerifyId, userId } = this.props.match.params;
        // debugger;
        this.props.completeEmailVerification(emailVerifyId, userId);
    }

    resendConfirmation = () => {
        this.props.sendConfirmation();
    }

    signOut = () => {
        this.props.signOutUser();
    }

    render() {
        const {
            email,
            confirmationResendLoading,
            confirmationResendDone,
            confirmationDone,
            confirmationFailed,
        } = this.props.settings;
        if (confirmationDone) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                    <Progress type="circle" percent={100} format={() => 'Success'} />
                    <div style={{ margin: '20px 0' }}>Redirecting..</div>
                </div>
            );
        }
        if (confirmationFailed) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                    <Progress type="circle" percent={0} format={() => 'Failed'} />
                    <div style={{ margin: '20px 0' }}>Something went wrong. Please try again or contact support.</div>
                </div>
            );
        }
        if (this.props.match.params.emailVerifyId && !confirmationDone) return <Spin />;
        return (
            <div className="signup-form">
                <Card
                    title={`You've Got Mail!`}
                    className="signup-card"
                    headStyle={{ fontSize: 20 }}
                    style={{ width: '500px' }}
                >
                    <div style={{ textAlign: 'left' }}>
                        {/* <Title level={4} >Check Your Email!</Title> */}
                        <span style={{ margin: '10px 0 20px 0', display: 'block' }}>
                            We have sent a confirmation link to <strong>{email}</strong>. Verify your address and we'll get you all set up!
                        </span>
                        {confirmationResendDone ? (
                            <Button
                                style={{ width: '100%' }}
                                icon={<CheckCircleOutlined />}
                                type="dotted"
                                disabled
                            >
                                Email sent
                            </Button>
                        ) : (
                                <Button
                                    style={{ width: '100%' }}
                                    icon={confirmationResendLoading ? <LoadingOutlined /> : <MailOutlined />}
                                    type="dotted"
                                    onClick={this.resendConfirmation}
                                    disabled={confirmationResendLoading}
                                >
                                    Resend Confirmation
                                </Button>
                            )}
                    </div>
                </Card>
                <div>
                    Not Your Email?&nbsp;
                    <span
                        style={{ cursor: 'pointer', color: '#1890ff' }}
                        onClick={this.signOut}
                    >
                        Logout
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifyEmail);