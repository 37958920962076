import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { message } from 'antd';

const isProduction = process.env.NODE_ENV === 'production';

const logger = {
    init: () => {
        if (!isProduction) return null;
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_KEY,
            normalizeDepth: 5,
            maxBreadcrumbs: 20,
            integrations: [ new Integrations.BrowserTracing() ], 
            tracesSampleRate: 1.0,
            // beforeSend(event, hint) {
            //     try {
            //         const isNonErrorException =
            //         event.exception.values[0].value.startsWith('Non-Error exception captured') ||
            //         hint.originalException['message'].startsWith('Non-Error exception captured');

            //         if (isNonErrorException) {
            //             // We want to ignore those kind of errors
            //             return null;
            //         }
            //         return event;
            //     } catch (e) {
            //         console.log('error in sentry scaffolding', e);
            //         return event;
            //     }
            // } 
        });
        Sentry.configureScope(function (scope) {
            scope.setTag("env", "frontend");
        });
    },
    logError: err => {
        if (!isProduction) return null;
        // debugger;
        Sentry.captureException(err);
    },
    setUser: (email, userId) => {
        if (!isProduction || !email) return null;
        Sentry.configureScope(function (scope) {
            scope.setUser({ email, userId });
        });
    },
    handleError: (err, showMessage = true) => {
        try {
            console.log('Error Occured');
            console.log(err);
            showMessage && message.warn(`Error: ${err.message}`, 10);
            logger.logError(err);
        } catch (e) {
            console.log('Sentry Error Reporting Failed');
            console.log(e);
        }
    }
}

export default logger;