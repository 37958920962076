import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Button, Table, Badge, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import _ from 'lodash';

class DashboardTable extends Component {
    getColumns = () => {
        return [
            {
                title: 'Номер Заказа',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Дата',
                dataIndex: 'moment',
                key: 'moment',
            },
            {
                title: 'Заказчик',
                dataIndex: 'description',
                key: 'description',
                render: (one, two) => two.agent.full.name || two.agent.full.legalTitle,
            },
            {
                title: 'Телефон',
                dataIndex: 'description',
                key: 'description',
                render: (one, two) => two.agent.full.phone,
            },
            // {
            //     title: 'Статус',
            //     dataIndex: 'description',
            //     key: 'description',
            //     render: (one, two) => two.agent.full.legalTitle,
            // },
            {
                title: 'Сумма',
                dataIndex: 'sum',
                key: 'sum',
                render: (one, two) => two.sum / 100,
            },
            {
                title: 'SMS ТТН',
                dataIndex: 'sum',
                key: 'sum',
                render: (one, two) => {
                    if (!two.description) return 'Нет';
                    const splitD = two.description.split('\n');
                    const marked = splitD.find(d => d.includes('Отправлено СМС с ТТН'));
                    if (!marked) return 'Нет';
                    return marked.split(':')[1];
                },
            },
            {
                title: 'Действия',
                dataIndex: 'Actions',
                key: 'actions',
                render: (one, two) => {
                    return (
                    <div style={{ width: 300 }}>
                        <Button onClick={() => this.props.showAgentModal(two.id)} style={{ marginRight: 10 }}>Накладная</Button>
                        <Button onClick={() => this.props.showSMSModal(two.id)} style={{ marginRight: 10 }}>Отправка СМС</Button>
                        {/* <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => this.props.onRemove(two._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                               <Button type="secondary" icon={<CloseOutlined />}>Remove</Button>
                            </Popconfirm> */}
                    </div>
                    )
                },
            }
        ];
    }

    expandedRowRender = record => {
        return (
            <div>
                <ReactJson src={record}  />
            </div>
        )
    }

    render() {
        const { data, isLoading } = this.props;
        const tableData = _.sortBy(data, 'date');
        // console.log('about to render table with data', tableData);
       
        return (
            <Table
                dataSource={tableData}
                columns={this.getColumns()}
                loading={isLoading}
                pagination={{ pageSize: 20, hideOnSinglePage: true }}
                rowKey='id'
                size="small"
                expandedRowRender={this.expandedRowRender}
            />
        ) 
    }
}

export default DashboardTable;
