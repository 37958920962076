import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { actions as SettingsReducer } from '../../store/SettingsReducer';

class Logout extends Component {
    componentWillMount() {
        document.title = 'Logout... | Zakluchator App';
        this.props.signOutUser();
    }

    render() {
        return (
            <div className="signup-form">
                Logging you out...
            </div>
        );
    }
}

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Logout);