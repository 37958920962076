import React, { Component as C } from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { actions as SettingsReducer } from '../store/SettingsReducer';

class PrivateRoute extends C {
    isAuthenticated = () => {
        const { loading, userId } = this.props.settings;
        // if u have a userId, you can view any id you want 
        return !loading && userId;
    }

    isVerified = () => {
        const { userId, emailVerified } = this.props.settings;
        return userId && emailVerified;
    }

    isActive = () => {
        const { userId, isActive } = this.props.settings;
        return userId && isActive;
    }

    renderRoute = Component => props => {
        if (!this.isAuthenticated()) {
            return <Redirect to={{ pathname: "/login/", state: { from: props.location } }} />;
        }
        if (!this.isVerified()) {
            return <Redirect to={{ pathname: "/verify/", state: { from: props.location } }} />;
        }
        if (!this.isActive()) {
            return <Redirect to={{ pathname: "/inactive/", state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }

    render() {
        const { component: Component, ...rest } = this.props;

        return <Route {...rest} render={this.renderRoute(Component)} />;
    }
};

const mapStateToProps = state => state;

const mapDispatchToProps = {
    ...SettingsReducer,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrivateRoute);