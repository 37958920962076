import { handleActions } from 'redux-actions';
import fire from '../services/fire';
import firebase from 'firebase';
import axios from 'axios';

import { message } from 'antd';
import history from '../history';
import fetch from '../services/fetch';
import logger from '../services/errorlogger';

const isProd = process.env.NODE_ENV === 'production';

export const actionTypes = {
    FETCH_USER_IP: 'FETCH_USER_IP',
    SET_USER_PROVIDER: 'SET_USER_PROVIDER',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_UPDATE_LOADING: 'USER_UPDATE_LOADING',
    USER_UPDATE_DONE: 'USER_UPDATE_DONE',
    USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
    USER_SINGNUP_LOADING: 'USER_SINGNUP_LOADING',
    USER_SINGNUP_DONE: 'USER_SINGNUP_DONE',
    USER_SINGNUP_ERROR: 'USER_SINGNUP_ERROR',
    FETCH_USER_DATA_LOADING: 'FETCH_USER_DATA_LOADING',
    FETCH_USER_DATA_DONE: 'FETCH_USER_DATA_DONE',
    FETCH_USER_DATA_ERROR: 'FETCH_USER_DATA_ERROR',
    APPLY_BETA_DONE: 'APPLY_BETA_DONE',
    APPLY_BETA_LOADING: 'APPLY_BETA_LOADING',
    CONFIRMATION_RESEND_LOADING: 'CONFIRMATION_RESEND_LOADING',
    CONFIRMATION_RESEND_DONE: 'CONFIRMATION_RESEND_DONE',
    CONFIRMATION_DONE: 'CONFIRMATION_DONE',
    CONFIRMATION_FAILED: 'CONFIRMATION_FAILED',
    TOKEN_VERIFICATION_SUCCESS: 'TOKEN_VERIFICATION_SUCCESS',
    TOKEN_VERIFICATION_LOADING: 'TOKEN_VERIFICATION_LOADING',
    TOKEN_VERIFICATION_FAILED: 'TOKEN_VERIFICATION_FAILED',
};

export const actions = {
    monitorLogin: () => async (dispatch, getState) => {
        try {
            const ensureUserData = async user => {
                if (!user) {
                    // console.log('NO USER IN MONITOR');
                    dispatch({ type: actionTypes.USER_LOGOUT });
                    return localStorage.removeItem('userId');
                }
                const { email, uid, providerData } = user;
                const provider = providerData && providerData[0] && providerData[0].providerId;
                dispatch({ type: actionTypes.SET_USER_PROVIDER, provider });
                logger.setUser(email, uid)
                localStorage.setItem('userId', user.uid);

                const token = await fire.auth().currentUser.getIdToken(true);
                localStorage.setItem('token', token);
                dispatch(actions.getUserData());
                
                // history.push('/app/dashboard/');
                
            }

            // dispatch(actions.signOutUser());

            await fire.auth().onAuthStateChanged(ensureUserData);

        } catch (e) {
            message.warn(`Error: ${e.message}`);
            logger.handleError(e, false);
        }
    },
    loginUser: userData => async (dispatch, getState) => {
        try {
            const { email, password } = userData;
            await fire.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

            await fire.auth().signInWithEmailAndPassword(email, password);
            history.push('/app/dashboard/');
        } catch (e) {
            message.warn(`Error: ${e.message}`);
            logger.handleError(e, false);
        }
    },
    signupUserApi: userData => async (dispatch, getState) => {
        try {
            const { siteUrl, email, password, inviteId = '', campaign = null } = userData;
            dispatch({ type: actionTypes.USER_SINGNUP_LOADING });

            const { data: user } = await fetch.put(`/users/`, { userData, inviteId, campaign });
            await fire.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            await fire.auth().signInWithEmailAndPassword(email, password);
            dispatch({ type: actionTypes.USER_SINGNUP_DONE });
            // enable adding site here because we dont need to do confirmation anymore
        } catch (e) {
            message.warn(`Error: ${e.message}`);
            dispatch({ type: actionTypes.USER_SINGNUP_ERROR });
            logger.handleError(e, false);
        }
    },
    loginUserGoogle: ({ siteUrl = '', inviteId = '', campaign = '' } = { siteUrl: '', inviteId: '', campaign: '' }) => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.USER_SINGNUP_LOADING });
            const provider = new firebase.auth.GoogleAuthProvider();
            const response = await fire.auth().signInWithPopup(provider);

            const token = response.credential.accessToken;
            const { family_name, given_name } = response.additionalUserInfo.profile;
            const { email, uid } = response.user;
            const userData = { email, userId: uid, token, name: `${given_name} ${family_name}` };

            try {
                await fetch.put(`/users/`, { userData, inviteId, campaign });
                dispatch({ type: actionTypes.USER_SINGNUP_DONE });
                dispatch(actions.getUserData());
                history.push('/app/dashboard/');
            } catch (error) {
                if (error && error.response && error.response.data === 'User Exists') {
                    dispatch({ type: actionTypes.USER_SINGNUP_DONE });
                    dispatch(actions.getUserData());
                    return history.push('/app/dashboard/');
                }
                throw new Error(error);
            }
        } catch (error) {
            dispatch({ type: actionTypes.USER_SINGNUP_ERROR });
            error && error.response && message.warn(`Error: ${error.response.data}`, 10);
            logger.handleError(error, false);
        }
    },
    updatePassword: userData => async (dispatch, getState) => {
        try {
            const { oldPassword, newPassword } = userData;
            dispatch({ type: actionTypes.USER_UPDATE_LOADING });
            const { email } = getState().settings;
            await fire.auth().signInWithEmailAndPassword(email, oldPassword);
            await fire.auth().currentUser.updatePassword(newPassword);
            dispatch({ type: actionTypes.USER_UPDATE_DONE });
            message.success(`Password Updated`, 1);
        } catch (e) {
            message.error(`Old Password is Incorrect`, 10);
            dispatch({ type: actionTypes.USER_UPDATE_ERROR });
            logger.handleError(e, false);
        }
    },
    resetPassword: userData => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.USER_UPDATE_LOADING });
            const { email } = userData;
            await fire.auth().sendPasswordResetEmail(email);
            dispatch({ type: actionTypes.USER_UPDATE_DONE });
            message.success(`Recovery Email Has Been Sent If User Exists, Refreshing...`, 15);
            setTimeout(() => window.location.reload(), 1000);
        } catch (e) {
            dispatch({ type: actionTypes.USER_UPDATE_ERROR });
            logger.handleError(e);
        }
    },
    updateUser: userData => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.USER_UPDATE_LOADING });
            await fetch.patch(`/users/`, { userData });
            dispatch({ type: actionTypes.USER_UPDATE_DONE });
            dispatch(actions.getUserData());
        } catch (e) {
            dispatch({ type: actionTypes.USER_UPDATE_ERROR });
            logger.handleError(e);
        }
    },
    updateUserEmail: ({ email, password, newEmail }) => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.USER_UPDATE_LOADING });
            await fire.auth().signInWithEmailAndPassword(email, password);
            await fire.auth().currentUser.updateEmail(newEmail);
            await fetch.patch(`/users/`, { userData: { email: newEmail } });
            dispatch({ type: actionTypes.USER_UPDATE_DONE });
            message.success(`Email Updated`, 1);
            dispatch(actions.getUserData());
        } catch (e) {
            dispatch({ type: actionTypes.USER_UPDATE_ERROR });
            message.warn(`Failed Updating Email: ${e.message}`, 10);
            logger.handleError(e, false);
        }
    },
    getUserData: (timeout = 0) => async (dispatch, getState) => {
        try {
            const { signupLoading } = getState().settings;
            if (signupLoading) return;
            dispatch({ type: actionTypes.FETCH_USER_DATA_LOADING });

            const getData = async () => await fetch.get(`/users/`)
                .then(({ data: user }) => dispatch({ type: actionTypes.FETCH_USER_DATA_DONE, user }))
                .catch(error => {
                    isProd ? dispatch(actions.signOutUser()) : dispatch({ type: actionTypes.FETCH_USER_DATA_ERROR });
                    logger.handleError(error);
                });

            setTimeout(getData, timeout);
            // timeout allows us to show loading screen longer than usual to make sure that webhooks have time to process. better than long polling
        } catch (e) {
            logger.handleError(e);
        }
    },
    signOutUser: () => async (dispatch, getState) => {
        try {
            const user = await fire.auth().signOut();
            // console.log('LOGGED OUT', user);
            localStorage.removeItem('userId');
            dispatch({ type: actionTypes.USER_LOGOUT });
            history.push('/login');
        } catch (e) {
            logger.handleError(e);
        }
    },
    applyBeta: (email, siteUrl) => async (dispatch, getState) => {
        try {
            const userData = { siteUrl, email };
            dispatch({ type: actionTypes.APPLY_BETA_LOADING });

            await fetch.put(`/users/apply`, { userData });
            dispatch({ type: actionTypes.APPLY_BETA_DONE });
        } catch (e) {
            logger.handleError(e);
        }
    },
    sendConfirmation: () => async (dispatch, getState) => {
        try {
            dispatch({ type: actionTypes.CONFIRMATION_RESEND_LOADING });
            await fetch.post(`/users/send-confirmation/`);
            dispatch({ type: actionTypes.CONFIRMATION_RESEND_DONE });
        } catch (e) {
            logger.handleError(e);
        }
    },
    completeEmailVerification: (id, userId) => async (dispatch, getState) => {
        try {
            await fetch.post(`/users/verify/${userId}/${id}`)
            dispatch({ type: actionTypes.CONFIRMATION_DONE });
            setTimeout(() => {
                dispatch(actions.getUserData());
            }, 1000)
        } catch (e) {
            dispatch({ type: actionTypes.CONFIRMATION_FAILED });
            logger.handleError(e);
        }
    },
    fetchUserIp: () => async (dispatch, getState) => {
        try {
            const response = await axios('https://api6.ipify.org?format=json');
            const userIp = response.data.ip;
            dispatch({ type: actionTypes.FETCH_USER_IP, userIp });
        } catch (e) {
            // most probably an adblocker
            // logger.handleError(e);
        }
    }
};

export const getDefaultState = () => {
    const defaultState = {
        userId: null,
        isTrial: false,
        role: null,
        userIp: null,
        providerData: null,

        loading: true,
        updateLoading: false,
        updateError: false,
        signupLoading: false,
        applyBetaLoading: false,
        applyBetaSuccess: false,

        confirmationResendLoading: false,
        confirmationResendDone: false,
        confirmationDone: false,
        confirmationFailed: false,

        tokenVerificationSuccess: false,
        tokenVerificationFailed: false,
        tokenVerificationLoading: false,
    };
    return defaultState;
};

export default handleActions({
    [actionTypes.SET_USER_PROVIDER]: (state, { provider }) => ({ ...state, provider }),
    [actionTypes.FETCH_USER_DATA_LOADING]: (state) => ({ ...state, loading: true }),
    [actionTypes.FETCH_USER_DATA_DONE]: (state, { user }) => {
        if (!user) return state;
        return ({ ...state, ...user, loading: false });
    },
    [actionTypes.FETCH_USER_DATA_ERROR]: (state) => ({ ...state, loading: false }),
    [actionTypes.USER_LOGOUT]: (state) => ({ ...state, ...getDefaultState(), loading: false }),
    [actionTypes.USER_UPDATE_LOADING]: (state) => ({ ...state, updateLoading: true }),
    [actionTypes.USER_UPDATE_DONE]: (state) => ({ ...state, updateLoading: false, updateError: false }),
    [actionTypes.USER_UPDATE_ERROR]: (state) => ({ ...state, updateLoading: true, updateError: true }),
    [actionTypes.USER_SINGNUP_LOADING]: (state) => ({ ...state, signupLoading: true, }),
    [actionTypes.USER_SINGNUP_DONE]: (state) => ({ ...state, signupLoading: false }),
    [actionTypes.USER_SINGNUP_ERROR]: (state) => ({ ...state, signupLoading: false }),
    [actionTypes.APPLY_BETA_LOADING]: (state) => ({ ...state, applyBetaLoading: true, applyBetaSuccess: false, }),
    [actionTypes.APPLY_BETA_DONE]: (state) => ({ ...state, applyBetaLoading: false, applyBetaSuccess: true }),
    [actionTypes.CONFIRMATION_RESEND_LOADING]: (state) => ({ ...state, confirmationResendLoading: true, confirmationResendDone: false, }),
    [actionTypes.CONFIRMATION_RESEND_DONE]: (state) => ({ ...state, confirmationResendLoading: false, confirmationResendDone: true }),
    [actionTypes.CONFIRMATION_DONE]: (state) => ({ ...state, confirmationDone: true, confirmationFailed: false }),
    [actionTypes.CONFIRMATION_FAILED]: (state) => ({ ...state, confirmationDone: false, confirmationFailed: true }),
    [actionTypes.TOKEN_VERIFICATION_FAILED]: (state) => ({ ...state, tokenVerificationLoading: false, tokenVerificationFailed: true, tokenVerificationSuccess: false }),
    [actionTypes.TOKEN_VERIFICATION_LOADING]: (state) => ({ ...state, tokenVerificationLoading: false, tokenVerificationFailed: false, tokenVerificationSuccess: false }),
    [actionTypes.TOKEN_VERIFICATION_SUCCESS]: (state) => ({ ...state, tokenVerificationLoading: false, tokenVerificationFailed: false, tokenVerificationSuccess: true }),
    [actionTypes.FETCH_USER_IP]: (state, { userIp }) => ({ ...state, userIp }),
}, getDefaultState());